import {ArticleGroups} from "./AnswerEnums";

type MeasurementQuestionsInfo = { id: string, label: string, step: string, productCategories: ArticleGroups[] }

export const MeasurementQuestions: Record<string, Record<string, MeasurementQuestionsInfo>> = {
    "sitBones": {
        'seatBoneDistance': {
            id: 'seatBoneDistance',
            label: 'Sitzknochenabstand (in cm)',
            step: "1",
            productCategories: [ArticleGroups.pelvis]
        },
    },
    "bodyHeight": {
        'bodyHeight': {
            id: 'bodyHeight',
            label: "Körpergröße (in cm)",
            step: "1",
            productCategories: [ArticleGroups.pelvis, ArticleGroups.clothing, ArticleGroups.bike]
        },
    },
    "bodyMeasurements": {
        'stepLength': {
            id: "step-length-result",
            label: "Schrittlänge (in cm)",
            step: "1",
            productCategories: [ArticleGroups.bike]
        },
        'shoulderWidth': {
            id: "shoulder-width-result",
            label: "Schulterbreite (in cm)",
            step: "1",
            productCategories: [ArticleGroups.bike]
        },
        'armLength': {
            id: "arm-length-result",
            label: "Armlänge (in cm)",
            step: "1",
            productCategories: [ArticleGroups.bike]
        },
    },
    "gripSize": {
        'gripWidth': {
            id: "grip-width-result",
            label: "Griffweite (in cm)",
            step: "0.1",
            productCategories: [ArticleGroups.hand]
        },
        'handWidth': {
            id: "hand-width-result",
            label: "Handbreite (in cm)",
            step: "0.1",
            productCategories: [ArticleGroups.hand]
        },
    },
    "shoeSize": {
        'shoeSize': {
            id: "shoe-size",
            label: "Schuhgröße",
            step: "0.5",
            productCategories: [ArticleGroups.foot]
        }
    }


}