import {createTheme} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    buttonLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonLarge?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    buttonLarge: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: { main: "#dd7a2f" },
    secondary: { main: "#949291" },
  },
  typography: {
    fontFamily: "Klavika Light",
    buttonLarge: {
      fontSize: 24,
    },
  },
});
