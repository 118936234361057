import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {en} from "./en";
import {de} from "./de";
import {Country_Code_Enum} from "../generated/graphql";

export enum Language {
    EN = "en",
    DE = "de"
}

export function countryCodeFromLanguage(language: string): Country_Code_Enum {
    switch (language) {
        case Language.EN:
            return Country_Code_Enum.En;
        case Language.DE:
            return Country_Code_Enum.De;
        default:
            console.warn("Unknown language: " + language);
            return Country_Code_Enum.En;
    }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: { ...en } },
      de: { translation: { ...de } },
    },
    lng: Language.DE,
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false,
    },
  });
