import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import {QRCode} from "react-qrcode-logo";
import {useStoreDispatch, useStoreSelector} from "../store/hooks";
import {reset, selectedArticleGroups, selectQuestionnaireId} from "../store/questionnaireSlice";
import {
    useCustomerAnswerSubscription,
    useEvaluateCustomerAnswerMutation,
    useManualMeasurementInputMutation,
    useRecommendationSubscription,
} from "../generated/graphql";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {MeasurementQuestions} from "../data/MeasurementQuestions";
import {useTranslation} from "react-i18next";

function determineErrorMessage(objectOfInterest: { min: number; max: number }, value: number) {
    return value < objectOfInterest.min ? "Wert ist zu klein" : value > objectOfInterest.max ? "Wert ist zu groß" : "";
}

const defaultValues: Record<string, number> = {
    seatBoneDistance: 12.0,
    bodyHeight: 170.0,
    stepLength: 80.0,
    shoulderWidth: 38.0,
    armLength: 50.0,
    gripWidth: 13.0,
    handWidth: 8.5,
    shoeSize: 42
}


export default function AppHandover() {
    const {t} = useTranslation()
    const questionnaireId = useStoreSelector(selectQuestionnaireId);

    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const dispatch = useStoreDispatch();

    const {data} = useRecommendationSubscription({variables: {id: questionnaireId!}});
    const {data: customerAnswerData} = useCustomerAnswerSubscription({variables: {id: questionnaireId!}});
    const customerAnswer = customerAnswerData?.customerAnswer[0]

    const articleGroup = useStoreSelector(selectedArticleGroups)

    const [manualResultsDialog, setManualResultsDialog] = useState(false);


    Object.keys(MeasurementQuestions).forEach(key => Object.values(MeasurementQuestions[key]).filter((e: any) => articleGroup.some((r: any) => e.productCategories.includes(r))))

    const relevantQuestions: Record<string, any> = {}
    const relevantQuestionKeys: string[] = []
    Object.entries(MeasurementQuestions).forEach(([groupKey, groupValues]) => {
            const filteredValues: Record<string, any> = {}
            Object.entries(groupValues).forEach(([key, value]) => {
                if (value.productCategories.some(r => articleGroup?.includes(r))) {
                    filteredValues[key] = value
                    relevantQuestionKeys.push(key)
                }
            })
            relevantQuestions[groupKey] = filteredValues
        }
    )
    const relevantDefaultValues: Record<string, number> = {}
    relevantQuestionKeys.forEach(key => relevantDefaultValues[key] = defaultValues[key])
    const [manualMeasurementResults, setManualMeasurementResults] = useState<{ [key: string]: number }>(relevantDefaultValues);


    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        const onlyBikeRecommendation = customerAnswer?.articleGroups[0] === 'bike'
        const bikeRecommendationDataAvailable = customerAnswer?.bodyHeight !== undefined && customerAnswer?.bodyHeight !== null

        if (onlyBikeRecommendation && bikeRecommendationDataAvailable) {
            navigate({pathname: `/results/${questionnaireId}`, search: searchParams.toString()});
        }

        const includesBikeRecommendation = customerAnswer?.articleGroups.includes('bike')
        const recommendationAvailable = data?.recommendations !== undefined && data.recommendations.length > 0
        if (includesBikeRecommendation && bikeRecommendationDataAvailable && recommendationAvailable) {
            navigate({pathname: `/results/${questionnaireId}`, search: searchParams.toString()});
        }

        if (recommendationAvailable) {
            navigate({pathname: `/results/${questionnaireId}`, search: searchParams.toString()});
        }
    }, [data, customerAnswerData, navigate]);

    async function cancelQuestionnaire() {
        setShowConfirmDialog(false);
        dispatch(reset());
        navigate({pathname: "/", search: searchParams.toString()});
    }

    const [setManualMeasurementInput] = useManualMeasurementInputMutation();
    const [evaluateCustomerAnswer] = useEvaluateCustomerAnswerMutation();

    function validateBeforeSubmit() {
        for (const manualResult in manualMeasurementResults) {
            if (manualMeasurementResults[manualResult] < minMaxValues[manualResult].min ||
                manualMeasurementResults[manualResult] > minMaxValues[manualResult].max) {
                return false;
            }
        }
        return true;
    }

    async function submitMeasurements() {
        console.log(manualMeasurementResults['shoeSize'])

        let submitValues = manualMeasurementResults
        if (manualMeasurementResults['shoeSize'] !== null || manualMeasurementResults['shoeSize'] !== undefined) {
            const roundedShoeSize = Math.round(manualMeasurementResults['shoeSize'])
            console.log('rounded', roundedShoeSize)
            submitValues.shoeSize = roundedShoeSize
            console.log('new', manualMeasurementResults)
        }

        if (validateBeforeSubmit()) {
            try {
                console.log('insert', manualMeasurementResults)
                await setManualMeasurementInput({
                    variables: {
                        ...submitValues,
                        answerId: questionnaireId,
                    },
                });
                evaluateCustomerAnswer({variables: {customerAnswerId: questionnaireId ?? ""}});
                setManualResultsDialog(false);
            } catch (e) {
                toast.error(
                    "Werte konnten nicht gespeichert werden. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut"
                );
            }
        } else {
            toast.error("Ein oder mehrere Werte sind zu klein oder zu groß.");
        }
    }

    const minMaxValues: { [key: string]: { min: number; max: number } } = {
        seatBoneDistance: {min: 5, max: 20},
        bodyHeight: {min: 131, max: 239},
        stepLength: {min: 55, max: 110},
        shoulderWidth: {min: 30, max: 55},
        armLength: {min: 50, max: 75},
        handWidth: {min: 5, max: 12},
        gripWidth: {min: 7, max: 20},
        shoeSize: {min: 36, max: 48}
    };

    return (
        <Container sx={{textAlign: "center", mt: 10}}>
            <Typography variant="h2">Vermessung durchführen</Typography>
            <Typography fontSize={24}>
                Wenden Sie sich jetzt direkt an Ihren Berater und lassen Sie sich mit neuster Technologie digital
                vermessen.
            </Typography>
            <Box p={3} width={"50%"} m={"auto"}>
                <QRCode
                    value={questionnaireId ?? ""}
                    ecLevel={"Q"}
                    quietZone={16}
                    logoImage="/favicons/apple-touch-icon.webp"
                    logoWidth={64}
                    logoHeight={64}
                    removeQrCodeBehindLogo={true}
                    qrStyle={"squares"}
                    fgColor={theme.palette.secondary.main}
                    size={256}
                    eyeRadius={4}
                />
            </Box>
            <Box display={"flex"} justifyContent={"center"} gap={2} mb={5}>
                <CircularProgress color={"primary"}/>
                <Typography variant="h5">Warte auf Ergebnisse...</Typography>
            </Box>
            <Button variant={"outlined"} onClick={() => setShowConfirmDialog(true)}>
                <Typography fontSize={20} px={2}>
                    Abbrechen
                </Typography>
            </Button>
            <Dialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Befragung abbrechen?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Wenn Sie die Befragung abbrechen, werden alle Ihre Eingaben gelöscht.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowConfirmDialog(false)} autoFocus color="secondary"
                            variant={"outlined"}>
                        Weitermachen
                    </Button>
                    <Button onClick={cancelQuestionnaire} variant={"outlined"}>
                        Befragung abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
            <br/>
            <Button variant={"outlined"} style={{marginTop: "4em"}} onClick={() => setManualResultsDialog(true)}>
                <Typography fontSize={14} px={2}>
                    Werte manuell eingeben
                </Typography>
            </Button>

            <Dialog open={manualResultsDialog} maxWidth={"lg"}>
                <div style={{margin: "1em", width: "max-content"}}>
                    <Typography variant={"h4"} marginBottom={"0.5em"} style={{width: "max-content"}}>
                        Geben Sie die manuellen Messergebnisse ein:
                    </Typography>
                    <FormControl fullWidth>
                        {Object.entries(relevantQuestions).map(([groupKey, groupValues]) => {
                            return <>
                                {groupValues !== undefined && Object.keys(groupValues).length > 0 &&
                                    <Typography variant={"h5"} style={{marginTop: "0.5em"}}>
                                        {t(groupKey)}
                                    </Typography>}
                                {Object.keys(groupValues).map((questionKey: any) => {
                                    const questionDetails = MeasurementQuestions[groupKey][questionKey]
                                    return <TextField
                                        id={questionDetails.id}
                                        label={questionDetails.label}
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                ...minMaxValues[questionKey],
                                                step: questionDetails.step,
                                                lang: "de-DE"
                                            }
                                        }}
                                        variant="outlined"
                                        value={manualMeasurementResults[questionKey] ?? defaultValues[questionKey]}
                                        margin={"dense"}
                                        onChange={async (e: any) => {
                                            const valueAsFloat = parseFloat(e.target.value)
                                            await setManualMeasurementResults({
                                                ...manualMeasurementResults,
                                                [questionKey]: valueAsFloat
                                            })
                                        }}
                                        onBlur={(e) => setManualMeasurementResults({
                                            ...manualMeasurementResults,
                                            [questionKey]: parseFloat(e.target.value),
                                        })}
                                        error={
                                            determineErrorMessage(minMaxValues[questionKey], manualMeasurementResults[questionKey]) !== ""
                                        }
                                        helperText={determineErrorMessage(
                                            minMaxValues[questionKey],
                                            manualMeasurementResults[questionKey]
                                        )}
                                    />
                                })}
                            </>
                        })}
                        <div style={{display: "flex", margin: "1em", alignItems: "end", width: "100%"}}>
                            <Button onClick={() => setManualResultsDialog(false)}>Abbrechen</Button>
                            <Button onClick={() => submitMeasurements()} variant={"contained"}
                                    style={{justifyContent: "end"}}>
                                Fertig
                            </Button>
                        </div>
                    </FormControl>
                </div>
            </Dialog>
        </Container>
    );
}
