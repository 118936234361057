import {ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache, split} from "@apollo/client";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";

/**
 * Loads the user authentication token from the URL and transforms it into the authentication header.
 *
 * @returns An object containing the `User-Authorization` authentication header.
 */
function loadHeaders() {
  const queryParameters = new URLSearchParams(window.location.search);
  const base64AuthenticationToken = queryParameters.get("auth");
  console.log("loadHeaders was executed", base64AuthenticationToken);

  return {
    "User-Authorization": `Basic ${base64AuthenticationToken}`,
  };
}

/**
 * Retrieves the base domain for the Hasura instance from an environment variable.
 *
 * @returns The Hasura base domain string.
 * @throws {Error} If the REACT_APP_HASURA_BASE_DOMAIN environment variable is not defined.
 */
const getBaseDomain = () => {
  const hasuraBaseDomain = process.env.REACT_APP_HASURA_BASE_DOMAIN
  console.log(`Using Hasura base domain: ${hasuraBaseDomain}`)
  if (!hasuraBaseDomain) throw new Error('HASURA_BASE_DOMAIN is not defined')
  return hasuraBaseDomain
}

const baseDomain = getBaseDomain()

// Construct the HTTP and WebSocket URIs based on the base domain
const useTls = baseDomain.includes('localhost') ? '' : 's'
const httpUri = `http${useTls}://${baseDomain}/v1/graphql`
const wsUri = `ws${useTls}://${baseDomain}/v1/graphql`

// Create HTTP link for queries and mutations
const httpLink = new HttpLink({
  uri: httpUri
})

// Create WebSocket link for subscriptions
const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      headers: loadHeaders()
    }
  }
})

// Define a link that logs GraphQL operation errors
const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(({ data, errors }) => {
    if (errors) {
      errors.forEach((error: any) => {
        if (!error) return;
        console.error(error);
      });
    }
    return { data, errors };
  });
});

// Split links so that subscription operations use the WebSocket link and others use the HTTP link
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  concat(errorLink, httpLink)
);

// Define middleware to attach headers to every outgoing operations
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: loadHeaders(),
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache(),
});
