import {AnswerConfig, QuestionConfig} from "../../data/QuestionConfig";
import {Box, Grid, Typography} from "@mui/material";
import {SQToggleButton} from "../SQToggleButton";
import {useTranslation} from "react-i18next";
import {useStoreDispatch, useStoreSelector} from "../../store/hooks";
import {
    selectAllAnswers,
    selectCurrentQuestionAnswers,
    toggleQuestionAnswer,
    updateQuestionAnswer,
} from "../../store/questionnaireSlice";

export default function Question({questionConfig}: { questionConfig: QuestionConfig }) {
    const {t} = useTranslation();
    const answer = useStoreSelector(selectCurrentQuestionAnswers);
    const allAnswers = useStoreSelector(selectAllAnswers);
    const dispatch = useStoreDispatch();

    const filteredAnswers =
        questionConfig.filterAnswer === undefined
            ? questionConfig.answers
            : questionConfig.answers.filter(questionConfig.filterAnswer(allAnswers));

    const answerCount = filteredAnswers.length;
    const evenNumberOfAnswers = answerCount % 2 === 0;
    function getColSpan(index: number) {
        if (index === answerCount - 1 && !evenNumberOfAnswers) {
            return 12;
        }
        return answerCount > 4 ? 6 : 12;
    }

    function handleClick(answer: AnswerConfig) {
        const value = answer.value ?? answer.text;
        if (questionConfig.canSelectMultipleOptions) {
            dispatch(toggleQuestionAnswer(value));
        } else {
            dispatch(updateQuestionAnswer([value]));
        }
    }

    return (
        <Box>
            <Typography variant="h2">{t(questionConfig.questionHeadline)}</Typography>
            <Grid container spacing={3} my={3}>
                {filteredAnswers.map((answerConfig, index) => {
                    return (
                        <Grid item xs={getColSpan(index)} key={answerConfig.text}>
                            <SQToggleButton
                                selected={answer.includes(answerConfig.value ?? answerConfig.text)}
                                fullWidth
                                onClick={() => handleClick(answerConfig)}
                            >
                                {answerConfig.image !== undefined ? (
                                    <Box mr={2}>
                                        <img src={answerConfig.image} alt=""
                                             style={{height: 35 / filteredAnswers.length + 'vh'}}/>
                                    </Box>
                                ) : null}
                                {t(answerConfig.text)}
                            </SQToggleButton>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}
