import {SNRData} from "./SNRData";
import {BicycleTypeEnum} from "./AnswerEnums";

export class BicycleType {
    public static readonly CITY_COMFORT: BicycleType = new BicycleType(
        BicycleTypeEnum.cityComfort.toString(),
        'City',
        0.66,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/city_comfort.png",
        new Map<number, SNRData>([
            [42, new SNRData(553, 631, 357, 385, "XXS-XS", 42, 17)],
            [43, new SNRData(561, 636, 364, 390, "XS", 43, 17)],
            [44, new SNRData(568, 639, 372, 393, "XS-S", 44, 17)],
            [45, new SNRData(578, 644, 377, 396, "S", 45, 18)],
            [46, new SNRData(596, 651, 381, 399, "S", 46, 18)],
            [47, new SNRData(604, 651, 382, 403, "S-M", 47, 19)],
            [48, new SNRData(617, 655, 384, 407, "S-M", 48, 19)],
            [49, new SNRData(625, 657, 385, 411, "M", 49, 19)],
            [50, new SNRData(635, 661, 389, 413, "M", 50, 20)],
            [51, new SNRData(638, 663, 391, 414, "M-L", 51, 20)],
            [52, new SNRData(642, 666, 393, 415, "M-L", 52, 20)],
            [53, new SNRData(644, 670, 394, 420, "L", 53, 21)],
            [54, new SNRData(646, 672, 396, 424, "L", 54, 21)],
            [55, new SNRData(648, 677, 398, 428, "L", 55, 22)],
            [56, new SNRData(651, 682, 399, 431, "L-XL", 56, 22)],
            [57, new SNRData(654, 686, 401, 434, "XL", 57, 22)],
            [58, new SNRData(659, 689, 403, 438, "XL", 58, 23)],
            [59, new SNRData(661, 694, 405, 441, "XL-XXL", 59, 23)],
            [60, new SNRData(663, 699, 408, 444, "XXL", 60, 24)],
            [61, new SNRData(668, 704, 413, 448, "XXL", 61, 24)],
            [62, new SNRData(672, 708, 417, 451, "XXL-3XL", 62, 24)],
            [63, new SNRData(675, 712, 421, 455, "XXL-3XL", 63, 25)],
            [64, new SNRData(678, 721, 424, 459, "3XL", 64, 25)],
            [65, new SNRData(683, 725, 428, 463, "3L-4XL", 65, 26)],
            [66, new SNRData(686, 729, 433, 469, "4XL", 66, 26)],
        ])
    );
    public static readonly TREKKING_COMFORT: BicycleType = new BicycleType(
        BicycleTypeEnum.trekkingComfort.toString(),
        'Trekking',
        0.63,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/trekking_comfort.png",
        new Map<number, SNRData>([
            [42, new SNRData(568, 627, 373, 390, "XXS-XS", 42, 17)],
            [43, new SNRData(575, 629, 376, 393, "XS", 43, 17)],
            [44, new SNRData(579, 630, 379, 396, "XS-S", 44, 17)],
            [45, new SNRData(584, 633, 382, 399, "S", 45, 18)],
            [46, new SNRData(590, 635, 385, 404, "S", 46, 18)],
            [47, new SNRData(595, 638, 388, 406, "S-M", 47, 19)],
            [48, new SNRData(600, 640, 389, 408, "S-M", 48, 19)],
            [49, new SNRData(602, 644, 392, 411, "M", 49, 19)],
            [50, new SNRData(608, 644, 393, 413, "M", 50, 20)],
            [51, new SNRData(614, 650, 395, 416, "M-L", 51, 20)],
            [52, new SNRData(619, 655, 397, 418, "M-L", 52, 20)],
            [53, new SNRData(621, 662, 392, 440, "L", 53, 21)],
            [54, new SNRData(625, 664, 394, 442, "L", 54, 21)],
            [55, new SNRData(629, 666, 395, 445, "L", 55, 22)],
            [56, new SNRData(635, 669, 399, 449, "L-XL", 56, 22)],
            [57, new SNRData(639, 674, 402, 453, "XL", 57, 22)],
            [58, new SNRData(642, 678, 404, 456, "XL", 58, 23)],
            [59, new SNRData(645, 680, 407, 459, "XL-XXL", 59, 23)],
            [60, new SNRData(649, 683, 411, 462, "XXL", 60, 24)],
            [61, new SNRData(654, 686, 414, 465, "XXL", 61, 24)],
            [62, new SNRData(658, 689, 416, 469, "XXL-3XL", 62, 24)],
            [63, new SNRData(662, 692, 420, 472, "XXL-3XL", 63, 25)],
        ])
    );
    public static readonly TREKKING_CROSS: BicycleType = new BicycleType(
        BicycleTypeEnum.trekkingCross.toString(),
        'MTB Tour & Travel',
        0.61,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/trekking_cross.png",
        new Map<number, SNRData>([
            [42, new SNRData(568, 627, 373, 390, "XXS-XS", 42, 17)],
            [43, new SNRData(575, 629, 376, 393, "XS", 43, 17)],
            [44, new SNRData(579, 630, 379, 396, "XS-S", 44, 17)],
            [45, new SNRData(584, 633, 382, 399, "S", 45, 18)],
            [46, new SNRData(590, 635, 385, 404, "S", 46, 18)],
            [47, new SNRData(595, 638, 388, 406, "S-M", 47, 19)],
            [48, new SNRData(600, 640, 389, 408, "S-M", 48, 19)],
            [49, new SNRData(602, 644, 392, 411, "M", 49, 19)],
            [50, new SNRData(608, 644, 393, 413, "M", 50, 20)],
            [51, new SNRData(614, 650, 395, 416, "M-L", 51, 20)],
            [52, new SNRData(619, 655, 397, 418, "M-L", 52, 20)],
            [53, new SNRData(621, 662, 392, 440, "L", 53, 21)],
            [54, new SNRData(625, 664, 394, 442, "L", 54, 21)],
            [55, new SNRData(629, 666, 395, 445, "L", 55, 22)],
            [56, new SNRData(635, 669, 399, 449, "L-XL", 56, 22)],
            [57, new SNRData(639, 674, 402, 453, "XL", 57, 22)],
            [58, new SNRData(642, 678, 404, 456, "XL", 58, 23)],
            [59, new SNRData(645, 680, 407, 459, "XL-XXL", 59, 23)],
            [60, new SNRData(649, 683, 411, 462, "XXL", 60, 24)],
            [61, new SNRData(654, 686, 414, 465, "XXL", 61, 24)],
            [62, new SNRData(658, 689, 416, 469, "XXL-3XL", 62, 24)],
            [63, new SNRData(662, 692, 420, 472, "XXL-3XL", 63, 25)],
        ])
    );
    public static readonly MTB_ALL_MOUNTAIN: BicycleType = new BicycleType(
        BicycleTypeEnum.mountainBikeAllMountain.toString(),
        'MTB Gravity & E-Performance',
        0.574,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/mtb_all_mountain.png",
        new Map<number, SNRData>([
            [33, new SNRData(567, 597, 353, 399, "3XS", 33, 13)],
            [34, new SNRData(572, 600, 363, 405, "3XS-XXS", 34, 13)],
            [35, new SNRData(577, 604, 373, 411, "XXS", 35, 14)],
            [36, new SNRData(582, 608, 381, 419, "XXS-XS", 36, 14)],
            [37, new SNRData(588, 612, 395, 425, "XS", 37, 15)],
            [38, new SNRData(594, 619, 409, 433, "XS", 38, 15)],
            [39, new SNRData(599, 621, 420, 438, "XS-S", 39, 15)],
            [40, new SNRData(601, 624, 429, 446, "S", 40, 16)],
            [41, new SNRData(604, 628, 434, 456, "S", 41, 16)],
            [42, new SNRData(606, 631, 438, 468, "S-M", 42, 17)],
            [43, new SNRData(609, 638, 448, 481, "S-M", 43, 17)],
            [44, new SNRData(612, 641, 452, 489, "S-M", 44, 17)],
            [45, new SNRData(616, 646, 459, 501, "M", 45, 18)],
            [46, new SNRData(619, 650, 465, 513, "M", 46, 18)],
            [47, new SNRData(625, 658, 470, 520, "M-L", 47, 19)],
            [48, new SNRData(630, 664, 477, 531, "M-L", 48, 19)],
            [49, new SNRData(634, 668, 482, 538, "M-L", 49, 19)],
            [50, new SNRData(636, 672, 487, 544, "L", 50, 20)],
            [51, new SNRData(637, 676, 494, 547, "L", 51, 20)],
            [52, new SNRData(640, 680, 499, 551, "L-XL", 52, 20)],
            [53, new SNRData(643, 684, 504, 555, "L-XL", 53, 21)],
            [54, new SNRData(646, 687, 509, 559, "XL", 54, 21)],
            [55, new SNRData(650, 690, 514, 563, "XL-XXL", 55, 22)],
            [56, new SNRData(653, 693, 519, 567, "XXL", 56, 22)],
        ])
    );
    public static readonly MTB_XC: BicycleType = new BicycleType(
        BicycleTypeEnum.mountainBikeCrossCountry.toString(),
        'MTB Tech & Trail',
        0.58,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/mtb_xc.png",
        new Map<number, SNRData>([
            [33, new SNRData(560, 596, 380, 398, "3XS", 33, 13)],
            [34, new SNRData(563, 597, 383, 402, "3XS-XXS", 34, 13)],
            [35, new SNRData(566, 599, 386, 406, "XXS", 35, 14)],
            [36, new SNRData(568, 600, 389, 410, "XXS-XS", 36, 14)],
            [37, new SNRData(571, 601, 391, 414, "XS", 37, 15)],
            [38, new SNRData(574, 603, 398, 420, "XS", 38, 15)],
            [39, new SNRData(577, 604, 401, 424, "XS-S", 39, 15)],
            [40, new SNRData(580, 607, 406, 428, "S", 40, 16)],
            [41, new SNRData(582, 608, 410, 432, "S", 41, 16)],
            [42, new SNRData(588, 611, 416, 439, "S-M", 42, 17)],
            [43, new SNRData(591, 612, 420, 445, "S-M", 43, 17)],
            [44, new SNRData(595, 614, 426, 452, "S-M", 44, 17)],
            [45, new SNRData(596, 616, 432, 456, "M", 45, 18)],
            [46, new SNRData(598, 617, 437, 461, "M", 46, 18)],
            [47, new SNRData(599, 620, 441, 467, "M-L", 47, 19)],
            [48, new SNRData(603, 626, 447, 476, "M-L", 48, 19)],
            [49, new SNRData(604, 629, 449, 480, "M-L", 49, 19)],
            [50, new SNRData(608, 632, 455, 485, "L", 50, 20)],
            [51, new SNRData(610, 635, 457, 489, "L", 51, 20)],
            [52, new SNRData(612, 639, 460, 494, "L-XL", 52, 20)],
            [53, new SNRData(615, 645, 465, 498, "L-XL", 53, 21)],
            [54, new SNRData(623, 648, 472, 502, "XL", 54, 21)],
            [55, new SNRData(627, 651, 478, 504, "XL-XXL", 55, 22)],
            [56, new SNRData(630, 654, 484, 507, "XXL", 56, 22)],
        ])
    );
    public static readonly GRAVEL: BicycleType = new BicycleType(
        BicycleTypeEnum.gravel.toString(),
        'Gravel',
        0.65,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/gravel.png",
        new Map<number, SNRData>([
            [42, new SNRData(489, 525, 349, 370, "XXS-XS", 42, 17)],
            [43, new SNRData(494, 532, 352, 372, "XS", 43, 17)],
            [44, new SNRData(497, 538, 355, 375, "XS-S", 44, 17)],
            [45, new SNRData(501, 544, 358, 377, "S", 45, 18)],
            [46, new SNRData(505, 551, 361, 380, "S", 46, 18)],
            [47, new SNRData(509, 557, 364, 382, "S-M", 47, 19)],
            [48, new SNRData(512, 562, 366, 384, "S-M", 48, 19)],
            [49, new SNRData(520, 570, 371, 387, "M", 49, 19)],
            [50, new SNRData(526, 575, 373, 389, "M", 50, 20)],
            [51, new SNRData(534, 583, 376, 391, "M-L", 51, 20)],
            [52, new SNRData(540, 589, 380, 395, "M-L", 52, 20)],
            [53, new SNRData(549, 598, 383, 397, "L", 53, 21)],
            [54, new SNRData(555, 602, 388, 399, "L", 54, 21)],
            [55, new SNRData(569, 612, 392, 403, "L", 55, 22)],
            [56, new SNRData(579, 616, 395, 406, "L-XL", 56, 22)],
            [57, new SNRData(589, 626, 397, 410, "XL", 57, 22)],
            [58, new SNRData(602, 632, 401, 414, "XL", 58, 23)],
            [59, new SNRData(610, 636, 403, 417, "XL-XXL", 59, 23)],
            [60, new SNRData(618, 641, 405, 420, "XXL", 60, 24)],
            [61, new SNRData(625, 652, 407, 425, "XXL", 61, 24)],
            [62, new SNRData(634, 656, 409, 431, "XXL-3XL", 62, 24)],
            [63, new SNRData(639, 661, 411, 438, "XXL-3XL", 63, 25)],
        ])
    );
    public static readonly RACING_BIKE: BicycleType = new BicycleType(
        BicycleTypeEnum.racingBike.toString(),
        'Road',
        0.665,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/racing_bike.png",
        new Map<number, SNRData>([
            [46, new SNRData(486, 503, 359, 374, "XXS-XS", 46, 18)],
            [47, new SNRData(494, 509, 363, 376, "XXS", 47, 19)],
            [48, new SNRData(499, 520, 366, 380, "XS", 48, 19)],
            [49, new SNRData(501, 528, 369, 383, "XS-S", 49, 19)],
            [50, new SNRData(504, 539, 371, 386, "S", 50, 20)],
            [51, new SNRData(512, 545, 374, 387, "S", 51, 20)],
            [52, new SNRData(521, 553, 377, 388, "S-M", 52, 20)],
            [53, new SNRData(527, 563, 379, 389, "M", 53, 21)],
            [54, new SNRData(537, 569, 382, 392, "M", 54, 21)],
            [55, new SNRData(545, 577, 385, 395, "M-L", 55, 22)],
            [56, new SNRData(555, 587, 388, 399, "L", 56, 22)],
            [57, new SNRData(565, 594, 391, 403, "L", 57, 22)],
            [58, new SNRData(575, 612, 395, 407, "L-XL", 58, 23)],
            [59, new SNRData(577, 610, 398, 411, "XL", 59, 23)],
            [60, new SNRData(586, 615, 401, 414, "XL-XXL", 60, 24)],
            [61, new SNRData(602, 632, 404, 417, "XL-XXL", 61, 24)],
            [62, new SNRData(612, 643, 407, 421, "XXL", 62, 24)],
            [63, new SNRData(618, 649, 410, 425, "XXL-3XL", 63, 25)],
            [64, new SNRData(625, 655, 412, 429, "3XL", 64, 25)],
        ])
    );
    // @ts-ignore
    public static readonly TRIATHLON_BIKE: BicycleType = new BicycleType(
        BicycleTypeEnum.triathlon.toString(),
        'Triathlon',
        0.67,
        "https://static-assets-bpj.pages.dev/profiler/email-images/bike_profile/triathlon_bike.png",
        new Map<number, SNRData>([
            [46, new SNRData(429, 472, 341, 384, "XXS-XS", 46, 18)],
            [47, new SNRData(445, 480, 351, 389, "XS", 47, 19)],
            [48, new SNRData(458, 492, 359, 396, "XS", 48, 19)],
            [49, new SNRData(465, 500, 366, 400, "XS-S", 49, 19)],
            [50, new SNRData(471, 507, 368, 429, "S", 50, 20)],
            [51, new SNRData(480, 516, 374, 436, "S", 51, 20)],
            [52, new SNRData(488, 525, 385, 414, "S-M", 52, 20)],
            [53, new SNRData(494, 532, 392, 420, "M", 53, 21)],
            [54, new SNRData(501, 540, 398, 429, "M", 54, 21)],
            [55, new SNRData(507, 549, 405, 434, "M-L", 55, 22)],
            [56, new SNRData(517, 559, 413, 441, "L", 56, 22)],
            [57, new SNRData(525, 568, 419, 451, "L", 57, 22)],
            [58, new SNRData(535, 578, 426, 459, "L-XL", 58, 23)],
            [59, new SNRData(544, 588, 432, 468, "XL", 59, 23)],
            [60, new SNRData(555, 602, 437, 480, "XL-XXL", 60, 24)],
            [61, new SNRData(564, 612, 442, 492, "XL-XXL", 61, 24)],
            [62, new SNRData(575, 622, 448, 504, "XXL", 62, 24)],
        ])
    );

    private constructor(
        public readonly internalId: string,
        public readonly name: string,
        public readonly srQuotient: number,
        public readonly imageUrl: string,
        public readonly snrMap: Map<number, SNRData>
    ) {
    }

    public static byInternalId(id: string): BicycleType | undefined {
        return Object.values(BicycleType).find((b) => b.internalId === id);
    }
}
