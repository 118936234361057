import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {QuestionnaireConfig} from "../data/QuestionConfig";
import type {RootState} from "./store";
import {apolloClient} from "../apollo";
import {CreateNewQuestionnaireDocument, UpsertCustomerAnswerDocument} from "../generated/graphql";
import {ArticleGroupsMatchingWithHasura, EBike} from "../data/AnswerEnums";


//FIXME better naming
export enum QuestionnaireStates {
    Uninitialized,
    Creating,
    CreateError,
    Created,
    Submitting,
    SubmitError,
    Submitted,
}

type Answers = Record<string, (string | number)[]>;

interface QuestionnaireState {
    currentQuestion: number;

    answers: Answers;

    questionnaireId: string | null;

    state: QuestionnaireStates;

    filteredQuestions: string[]
}


function answersToGraphqlVariables(answers: Answers): Record<string, any> {
    function getValueOrNull(name: string): string | number | null {
        let answer = answers[name] as (string | number)[] | undefined;
        if (answer === undefined || answer.length !== 1) {
            return null;
        }
        return answer[0];
    }

    function getValuesAsGraphqlInput(name: string, fieldName: string): Record<string, string>[] {
        let answer = answers[name] as string[] | undefined;
        const values = answer ?? [];
        return values.map((v) => ({[fieldName]: v}));
    }


    return {
        article_groups: answers["article_groups"].map((answer) => ArticleGroupsMatchingWithHasura[answer]),
        sex: getValueOrNull("sex"),
        bicycle_type: getValueOrNull("bicycle_type"),
        e_bike: getValueOrNull("e_bike") === EBike.eBikeYes,
        driving_frequency: getValueOrNull("driving_frequency"),
        driving_duration: getValueOrNull("driving_duration"),
        seat_position: getValueOrNull("seat_position"),
        weight: getValueOrNull("weight"),
        pelvic_problems: getValuesAsGraphqlInput("pelvic_problems", "pelvic_problem_id"),
        hand_problems: getValuesAsGraphqlInput("hand_problems", "hand_problem_id"),
        leg_axis: getValueOrNull("leg_axis"),
        foot_type: getValueOrNull("foot_type"),
        foot_position: getValueOrNull("foot_position"),
        foot_problems: getValuesAsGraphqlInput("foot_problems", "foot_problem_id"),
    };
}

export const startQuestionnaire = createAsyncThunk<string, void>(
    "questionnaire/start",
    async (_, thunkAPI): Promise<string> => {
        thunkAPI.dispatch(reset());
        const mutationResult = await apolloClient.mutate({
            mutation: CreateNewQuestionnaireDocument,
        });
        const id = mutationResult.data?.createAnswer?.customer_answer.id;
        if (id !== undefined) {
            return id;
        } else {
            thunkAPI.rejectWithValue("Could not get Id");
        }
        thunkAPI.rejectWithValue(mutationResult.errors);
        return "";
    }
);

export const submitAnswers = createAsyncThunk<void, void>(
    "questionnaire/submit",
    async (_, thunkApi): Promise<void> => {
        let state = thunkApi.getState() as RootState;
        const variables = answersToGraphqlVariables(state.questionnaire.answers);
        variables["id"] = state.questionnaire.questionnaireId as string;
        await apolloClient.mutate({mutation: UpsertCustomerAnswerDocument, variables});
    }
);


const initialState: QuestionnaireState = {
    state: QuestionnaireStates.Uninitialized,
    currentQuestion: 0,
    questionnaireId: null,
    answers: {},
    filteredQuestions: ['article_groups']
};

function setNextQuestionDefaultAnswer(state: QuestionnaireState) {
    if (state.currentQuestion !== filteredQuestions.length - 1) {
        const nextQuestion = QuestionnaireConfig[state.filteredQuestions[state.currentQuestion]]
        if (state.answers[nextQuestion.questionId] === undefined) {
            state.answers[nextQuestion.questionId] = nextQuestion.defaultValue(state.answers);
        }
    }
}

export const questionnaireSlice = createSlice({
    name: "questionnaire",
    initialState,
    reducers: {
        reset: (state) => {
            state.currentQuestion = 0;
            state.answers = {};
            state.questionnaireId = null;
            state.filteredQuestions = ['article_groups']
            setNextQuestionDefaultAnswer(state);
        },
        filterRelevantQuestions: (state) => {
            const selectedArticleGroups = JSON.parse(JSON.stringify(state.answers))['article_groups'];

            const QuestionnaireConfigArray = Object.entries(QuestionnaireConfig);

            const questionKeysForSelectedGroups = QuestionnaireConfigArray
                .filter(([key, value]) => value.productCategories.some(r => selectedArticleGroups?.includes(r)))
                .map(([key, value]) => key);

            state.filteredQuestions = questionKeysForSelectedGroups
            amountOfQuestions = questionKeysForSelectedGroups.length
        },
        nextQuestion: (state) => {
            if (state.currentQuestion < amountOfQuestions - 1) {
                state.currentQuestion++
            }
            setNextQuestionDefaultAnswer(state);

        },
        previousQuestion: (state) => {
            if (state.currentQuestion > 0) {
                state.currentQuestion--;
            }
        },
        updateQuestionAnswer: (state, action: PayloadAction<(string | number)[]>) => {
            const currentQuestionConfig = QuestionnaireConfig[state.filteredQuestions[state.currentQuestion]]
            let questionId = currentQuestionConfig.questionId;
            state.answers[questionId] = action.payload;
        },
        toggleQuestionAnswer: (state, action: PayloadAction<string | number>) => {
            //FIXME find a more elegant solution for mapping question ids and answers
            const currentQuestionConfig = QuestionnaireConfig[state.filteredQuestions[state.currentQuestion]]
            let questionId = currentQuestionConfig.questionId;
            if (state.answers[questionId] === undefined) {
                state.answers[questionId] = [action.payload];
            } else {
                state.answers[questionId] = state.answers[questionId].includes(action.payload)
                    ? state.answers[questionId].filter((v) => v !== action.payload)
                    : [...state.answers[questionId], action.payload];
            }
        },
        setQuestionnaireId: (state, action: PayloadAction<string | null>) => {
            state.questionnaireId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(startQuestionnaire.pending, (state) => {
                state.state = QuestionnaireStates.Creating;
            })
            .addCase(startQuestionnaire.rejected, (state) => {
                state.state = QuestionnaireStates.CreateError;
            })
            .addCase(startQuestionnaire.fulfilled, (state, action: PayloadAction<string>) => {
                state.questionnaireId = action.payload;
                state.state = QuestionnaireStates.Created;
            })
            .addCase(submitAnswers.pending, (state) => {
                state.state = QuestionnaireStates.Submitting;
            })
            .addCase(submitAnswers.rejected, (state) => {
                state.state = QuestionnaireStates.SubmitError;
            })
            .addCase(submitAnswers.fulfilled, (state) => {
                state.state = QuestionnaireStates.Submitted;
            });
    },
});

export const {
    reset,
    filterRelevantQuestions,
    nextQuestion,
    previousQuestion,
    updateQuestionAnswer,
    toggleQuestionAnswer,
} =
    questionnaireSlice.actions;

export const selectCurrentQuestionIndex = (state: RootState) => state.questionnaire.currentQuestion;

export const selectCurrentQuestionId = (state: RootState) => {
    return state.questionnaire.filteredQuestions[state.questionnaire.currentQuestion];
}

export const selectCurrentQuestionAnswers = (state: RootState) => {
    //TODO maybe it's smarter to reference question by index?
    const currentQuestionId = QuestionnaireConfig[state.questionnaire.filteredQuestions[state.questionnaire.currentQuestion]].questionId
    return state.questionnaire.answers[currentQuestionId] ?? [];
};

export const selectAllAnswers = (state: RootState) => state.questionnaire.answers;

export const selectQuestionnaireId = (state: RootState) => state.questionnaire.questionnaireId;

export const selectQuestionnaireState = (state: RootState) => state.questionnaire.state;

export const selectIsCreating = (state: RootState) => state.questionnaire.state === QuestionnaireStates.Creating;

export const selectedArticleGroups = (state: RootState) => state.questionnaire.answers.article_groups

export const filteredQuestions = (state: RootState) => state.questionnaire.filteredQuestions

export let amountOfQuestions = 1


export default questionnaireSlice.reducer;
