export const en = {
    global_app_title: "SQlab Profiler",
    global_error_label: "Oopsie Doopsie, something went wrong here!",
    language_german: "German",
    language_english: "English",
    measurement_metric: "Metric (cm/kg)",
    measurement_imperial: "Imperial (inch/lbs)",
    app_currency_symbol: "€",
    app_password_label: "Password",
    app_password_confirm: "Repeat password",
    app_password_reset: "Reset Password",
    app_password_change: "Change Password",
    app_name_change: "Change Name",
    app_password_forgot: "Forgot Password?",
    app_password_repeat: "Repeat Password",
    app_name_repeat: "Repeat Name",
    app_password_wrong: "The password you entered is incorrect.",
    app_email: "Email",
    app_email_sent: "Sent",
    app_email_label: "Email address",
    app_email_wrong_format:
        "The email address doesn't seem to be correct. Please check the email address you entered and try again.",
    app_create_profile: "Create User",
    app_name_label: "Name",
    app_delete: "Delete",
    app_print: "Print",
    app_search: "Search",
    app_scan: "Scan",
    app_page: "Page {0}",
    navigation_confirm: "Continue",
    navigation_send: "Submit",
    navigation_back: "Back",
    navigation_logout: "Log out",
    navigation_menu: "Menu",
    navigation_close: "Close",
    navigation_cancel: "Cancel",
    legal_consent: "Consent",
    legal_signature: "Signature",
    legal_part_1: "I accept the ",
    legal_part_2: "Terms of Use",
    legal_part_3: " and took note of the ",
    legal_part_4: "Data protection",
    legal_part_5: ".",
    legal_decline: "Reject",
    legal_pictures: "* Images are usually protected by copyright.",
    legal_not_accepted: "You must agree to the terms of use and take note of the data protection declaration to do this!",
    feature_flag_disabled_info: "Unfortunately, this feature is currently unavailable.",
    toast_error: "Error!",
    toast_understood: "Understood!",
    toast_alright: "Alright!",
    toast_questionnaire_title: "Profiler Questionnaire",
    toast_questionnaire_bodyheight_cant_continue_info:
        "Without specifying your height, no meaningful result can be calculated!",
    toast_questionnaire_personal_information_cant_continue_info:
        "The specified email is not a valid email address. Please enter a valid or no email address to continue.",
    toast_questionnaire_privacy_agreement_cant_continue_info:
        "Without consent to the processing of your health data, we cannot recommend best suitable products to you.",
    toast_questionnaire_gripsize_cant_continue_info: "Please enter valid measurements of the hand.",
    no_connection_warning_banner: "YOU ARE OFFLINE - PLEASE CHECK YOUR CONNECTION",
    unsupported_device_warning_banner: "IPAD MODEL NOT SUPPORTED - FAILURES MAY OCCUR",
    upgrade_app_warning_banner: "APP VERSION OUTDATED - PLEASE UPDATE AS SOON AS POSSIBLE!",
    upgrade_app_warning: "This app version is no longer supported!\nPlease update this app via AppStore.",
    upgrade_app_open_testflight_button: "Open AppStore",
    logout_confirmation_dialog_text: "Are you sure you want to log out of your vendor account?",
    welcome_screen_title: "Welcome!",
    menu_search_question_label: "Browse Questionnaires",
    menu_post_fitting_label: "Bike Fitting",
    menu_experimental_label: "Experimental Features",
    menu_question_label: "Start Questionnaire",
    menu_health_label: "Status Report",
    menu_settings_label: "Settings",
    menu_statistics_label: "Statistics",
    menu_quickstart_label: "Quick-Start",
    new_label: "NEW",
    pelvis: "Pelvis",
    hand: "Hand",
    foot: "Foot",
    clothing: "Clothes",
    bike: "Bike",
    settings_health_rc_availability: "RC availability",
    settings_health_connection_mobile: "Mobile Data",
    settings_health_connection_wifi: "Wi-fi",
    settings_health_connection_disconnected: "No connection",
    settings_health_connection_error: "No connection (error)",
    settings_health_connection_status: "Connection status",
    settings_health_cv_availability: "CV availability",
    settings_health_hasura_availability: "Hasura availability",
    settings_health_device_info: "Device info",
    settings_health_build_info: "Build info",
    settings_health_logs: "Logs",
    settings_health_licenses: "Licenses",
    settings_headline_settings: "Settings",
    settings_headline_support: "Support",
    settings_headline_information: "Information",
    settings_select_manufacturers_button: "Select manufacturers",
    settings_select_product_groups_button: "Select product groups",
    settings_select_manufacturers_headline: "Select bike manufacturer",
    settings_select_product_groups_headline: "Select product groups",
    settings_select_product_groups_table_contact_point: "Contact point",
    settings_select_product_groups_table_recommended_products: "Recommended product groups",
    settings_select_product_groups_table_pelvis: "Saddle, Shorts",
    settings_select_product_groups_table_hand: "Grips / Handlebar Tape, Innerbarends, Gloves, Handlebar, Stem",
    settings_select_product_groups_table_foot: "Pedals, Insoles",
    settings_select_product_groups_table_clothes: "Jerseys, Shorts",
    settings_select_product_groups_table_bike: "Bike-Sizing, Bicycles",
    settings_select_product_groups_info: "The product group selection is only applied to the next questionnaire.",
    settings_select_manufacturers_select_all: "Select all",
    settings_select_manufacturers_deselect_all: "Deselect all",
    settings_new_name_field: "New Name",
    settings_new_password_field: "New Password",
    settings_password_name_empty: "You have not entered a new name. Please check your entry.",
    settings_password_password_empty: "You have not entered a password. Please check your entry.",
    settings_password_password_not_repeated:
        "You have made a mistake while repeating the password. Please check your entry.",
    settings_password_request_timeout:
        "Connection timed out. Currently the password cannot be reset, please try again later!",
    settings_password_request_error:
        "Due to a server error, the password cannot currently be reset currently. Please try again later!",
    settings_password_request_success: "You have successfully changed your password!",
    settings_name_request_success_part1: "You have successfully changed your name from {0} to {1}",
    settings_feedback_button: "Feedback",
    settings_status_button: "Status",
    settings_contact_button: "Contact",
    settings_imprint_button: "Imprint",
    settings_privacy_button: "Privacy Policy",
    settings_usage_agreement_button: "Terms of Use",
    settings_contact_text:
        "For questions and suggestions about the SQlab Profiler App, contact your supervisor {0} or email profiler@sq-lab.com!",
    settings_contact_note: "Note",
    settings_contact_text_1: "SQlab assists you with questions, problems and suggestions. Please contact us via e-mail ",
    settings_contact_text_2: " or your supervisor ",
    settings_contact_text_3: "Always include your account number in any requests:",
    settings_changelanguage_headline: "Change language / units",
    settings_changelanguage_failure: "The language could not be updated. Please try again!",
    settings_deactivate_label: "Deactivate",
    settings_deactivate_user_text: "Deactivate this user",
    settings_user_is_deactivated: "User was deactivated",
    settings_deactivate_user_confirmation_text:
        "Are you sure about deactivating this user? The user will be permanently deactivated.",
    questionnaire_recommended_products_button: "Products",
    questionnaire_product_sold_button: "Sold",
    questionnaire_sell_product_button: "Sell",
    questionnaire_sell_product_confirmation_info: "Are you sure you sold this product to a customer?",
    questionnaire_sell_product_confirmation_confirm: "Yes, sold!",
    questionnaire_product_no_description: "No description available.",
    questionnaire_product_description_headline: "Description:",
    questionnaire_product_size_headline: "Size:",
    questionnaire_product_available_soon: "Item available soon",
    questionnaire_product_open_online_shop: "Open online shop",
    questionnaire_no_products_found: "Unfortunately, no products were found.",
    questionnaire_email_success: "Email sent successfully!",
    questionnaire_email_failure: "Failed to send email!",
    questionnaire_no_product_groups:
        "No product groups have been selected. Please select product groups in the settings.",
    questionnaire_data_confirmation: "Are you sure that this questionnaire should be permanently deleted?",
    questionnaire_data_delete_success: "Data deleted successfully!",
    questionnaire_data_delete_failure: "Data could not be deleted!",
    camera_help_label: "Help",
    camera_start_process_button: "Start image recognition",
    camera_repeat_button: "Take another image",
    profile_overview_info_box_content:
        "Note: The users of your vendor account are identical for the Profiler App and the iQlab e-learning platform and can be used on both platforms.",
    profile_forgot_password_email_not_valid: "The entered email address is invalid! Please enter a valid email address!",
    answeroverview_detail_loading_text: "Loading questionnaire ...",
    answeroverview_products_loading_text: "Loading article recommendations ...",
    answeroverview_overview_loading_text: "Loading questionnaire overview ...",
    answeroverview_filter_loading_text: "Applying filter ...",
    answeroverview_next_page_loading_text: "Loading next page ...",
    answeroverview_previous_page_loading_text: "Loading previous page ...",
    retry_button: "Try again",
    email_language_selection_question: "In which language should emails be sent?",
    edit_answers_button: "Edit answers",
    edit_answers_after_done_button: "Answers",
    question_help_button: "Help",
    question_cancel_button: "Cancel",
    question_cancel_dialog_text: "Should the questionnaire be canceled?",
    question_cancel_dialog_continue_button: "Cancel",
    question_cancel_dialog_confirm_button: "Keep going",
    question_submitted_loading_text: "Calculating matching articles ...",
    question_starting_loading_text: "Preparing the questionnaire ...",
    unknown_exception:
        "Unfortunately, an unknown error occurred during this action.\nIf an app update is available, please install it.\nOtherwise, please try to perform the action again.",
    timeout_exception: "Connection timed out.\nCheck your internet connection and try again.",
    no_connection_exception: "This action cannot be performed because there is no connection to the internet.",
    questionnaire_no_result_exception:
        "Unfortunately, no article recommendations could be determined for these entries.\nUse more specific inputs and try again.",
    questionnaire_prepare_server_error_exception:
        "The questionnaire cannot currently be started due to server problems. Please try again later!",
    questionnaire_finish_server_error_exception: "The questionnaire could not be processed. Please try again later.",
    questionnaire_summary_headline: "Answer Summary",
    questionnaire_summary_no_answer_label: "No answer",
    questionnaire_summary_pictures_headline: "Digital Measurements",
    questionnaire_finish_headline: "Recommended Articles",
    questionnaire_sold_product_stamp: "SOLD",
    post_buy_fitting_loading_text: "Start Post Buy Fitting Analysis ...",
    markdown_document_no_load: "The requested document could not be loaded.\nPlease check the internet connection.",
    to_home_button_text: "Menu",
    to_home_cancel_text: "Continue",
    to_home_info_text: "Are you sure you want to return to the main menu?",
    to_home_confirmation_text: "Main Menu",
    no_data_available: "Not specified",
    no_problem_available: "No problem stated",
    multiple_choice_title: "Multiple Choice",
    sex_question_question_text: "Gender",
    female: "Woman",
    male: "Man",
    divers: "Diverse",
    ebike_question_question_text: "E-Bike",
    ebike_yes: "Yes",
    ebike_no: "No",
    driving_duration_question_question_text: "Riding Time / Day",
    lessThan45min: "Less than 45 minutes",
    between45minAnd2h: "45 minutes to 2 hours",
    moreThan2h: "More than 2 hours",
    bicycle_type_question_question_text: "Bicycle Type",
    triathlon_bike: "Triathlon",
    racing_bike: "Road",
    mtb_xc: "MTB Tech & Trail",
    mtb_all_mountain: "MTB Gravity & E-Performance",
    trekking_cross: "MTB Tour & Travel",
    trekking_comfort: "Trekking",
    city_comfort: "City",
    gravel: "Gravel",
    seat_position_question_question_text: "Sitting Position",
    triathlon: "Triathlon",
    sportive: "Athletic",
    moderate: "Moderate",
    slightly_bent: "Slightly bent",
    upright: "Upright",
    leg_axis_question_question_text: "Leg Axis",
    o_legs: "Bowlegs",
    straight_legs: "Straight legs",
    x_legs: "Knock knees",
    foot_position_question_question_text: "Foot Position",
    v: "V-shaped",
    straight: "Straight",
    gripping_distance_question_question_text: "Handle Size",
    S: "S",
    M: "M",
    L: "L",
    XL: "XL",
    driving_frequency_question_question_text: "Riding Frequency",
    several_times_a_week: "Several times a week",
    one_time_a_week: "Once a week",
    every_two_weeks: "Every 2 weeks",
    every_four_weeks: "Every 4 weeks",
    foot_type_question_question_text: "Foot Type",
    arches_foot: "Arches foot",
    normal: "Normal foot",
    arched_foot: "Arched feet",
    flat_foot: "Flat foot",
    foot_problem_question_question_text: "Problems: Foot",
    numb_toes: "Numbness toes",
    knee_discomfort: "Knee discomfort",
    hand_problem_question_question_text: "Problems: Hand",
    numb_thumb_index_and_middle_finger: "Numb thumb, index and middle finger",
    numb_little_and_ring_finger: "Deaf little one and ring finger",
    wrist: "Wrist",
    upper_back: "Upper back (shoulder / neck / cervical vertebrae)",
    seat_bone_distance_question_question_text: "Sit Bone Distance",
    shoe_size_question_question_text: "Shoe Size (EU)",
    weight_question_question_text: "Weight",
    weight_question_sub60_label: "Up to 60 kg",
    weight_question_sub70_label: "Up to 70 kg",
    weight_question_sub80_label: "Up to 80 kg",
    weight_question_sub90_label: "Up to 90 kg",
    weight_question_sub100_label: "Up to 100 kg",
    weight_question_sub110_label: "Up to 110 kg",
    weight_question_sub120_label: "Up to 120 kg",
    weight_question_plus120_label: "More than 120 kg",
    weight_question_sub60_label_imperial: "Up to 132 lbs.",
    weight_question_sub70_label_imperial: "Up to 153 lbs.",
    weight_question_sub80_label_imperial: "Up to 176 lbs.",
    weight_question_sub90_label_imperial: "Up to 198 lbs.",
    weight_question_sub100_label_imperial: "Up to 220 lbs.",
    weight_question_sub110_label_imperial: "Up to 242 lbs.",
    weight_question_sub120_label_imperial: "Up to 264 lbs.",
    weight_question_plus120_label_imperial: "More than 264 lbs.",
    weight_question_custom_label: "Up to {0} kg",
    pelvic_problems_question_question_text: "Problems: Pelvis",
    numbness: "Numbness",
    sitbones: "Sit bones",
    coccyx: "Coccyx",
    pubic_bone: "Pubic bone",
    prostate: "Prostate",
    lower_back: "Lower back",
    pelvic_malalignment: "Pelvic misalignment",
    heat_sweat: "Heat / sweat",
    personal_question_question_text: "Contact",
    personal_question_question_optional_disclaimer: "* Providing this information is optional",
    privacy_agreement_question_text: "Data Processing",
    ergonomics_profile_label: "Ergonomics Profile",
    summary_page_other_values_label_ipad: "Contact Information & Personal Preferences ",
    summary_page_other_values_label_iphone: "Contact Information &\nPersonal Preferences ",
    summary_page_other_from_connectingword: "from",
    body_measurements_question_question_text: "Ergonomics Profile",
    body_measurements_help_text:
        "When taking the body measurement, pay attention to the following points:\n\n\u2022 There is only one person in the frame\n\u2022 The room is naturally lit.\n\u2022 The customer is recorded from the front\n\u2022 During the recording, the device is held still and facing the customer\n\u2022 The customer can be seen in full in the image section\n\u2022 If possible, the customer does not wear any shoes during the measurement",
    body_measurements_help_text_title_iPad: "Information on digital body measurement",
    body_measurements_help_text_title_iPhone: "Body Measurement Info",
    body_height_question_question_text: "Height",
    bike_fitting_analysis_title: "Bike Fitting Analysis",
    bike_fitting_analysis_success_title: "Bike Fitting Analysis Results",
    bike_fitting_analysis_failure: "Analysis failed",
    bike_fitting_start_button: "Start",
    bike_fitting_start_guide_title: "Guide",
    bike_fitting_start_guide:
        "1. Place test person on standing bike.\n2. Subject must pedal before recording is started.\n3. Start recording. After recording, wait for the analysis to be completed! The process may take up to one minute.",
    bike_fitting_restart_button: "Restart",
    bike_fitting_result_max_knee_angle_title: "Maximal Knee Angle",
    bike_fitting_result_knee_lot_title: "Knee Lot",
    bike_fitting_result_adjustments_title: "Necessary Adjustments",
    bike_fitting_result_adjustments_content:
        "In the current version of the app, no statements can yet be made about possible adjustments to the bike. If you want to send us feedback on the measurement results, please use the feedback form in the app settings.",
    bike_fitting_result_show_graph_button: "View Graph",
    bike_fitting_loading_video_process: "Video is being processed...",
    bike_fitting_knee_angle: "Knee Angle",
    bike_fitting_min_knee_angle: "Min. Knee Angle",
    bike_fitting_max_knee_angle: "Max. Knee Angle",
    bike_fitting_knee_angle_not_measured: "Not measured",
    bike_fitting_change_view_direction: "Change direction",
    health_page_title: "Status report",
    try_vendor_login_loading_text: "Trying to authenticate the vendor account ...",
    try_profile_login_loading_text: "Trying to authenticate the user ...",
    vendor_login_loading_text: "Authenticating the vendor account ...",
    profile_login_loading_text: "Authenticating the user ...",
    create_profile_loading_text: "Creating the new user ...",
    login_button: "Log in",
    create_profile_button: "Create User",
    create_profile_information:
        "With a user, you carry out questionnaires,\nsee historical questionnaires and review your\nstatistics and commission!\nThe created user can also be used on iQlab.",
    create_profile_first_name_field: "First name",
    create_profile_last_name_field: "Last name",
    create_profile_first_name_empty: "The given first name cannot be empty. Please enter a first name for the user.\n\n",
    create_profile_last_name_empty: "The given last name cannot be empty. Please enter a last name for the user.\n\n",
    create_profile_email_invalid:
        "The specified email is not a valid email address. Please enter a valid email address for the user.\n\n",
    create_profile_password_empty: "The specified password cannot be empty. Please enter a password for the user.\n\n",
    create_profile_password_repeat_no_match:
        "The password does not match the repeated password. Both entries must be identical.\n\n",
    login_info_toast_title: "SQlab Login",
    login_password_reset_iqlab_hint:
        "If you reset the password, the password of your iQlab account will also be reset. Would you like to continue?",
    profile_forgot_password_info:
        "To reset your password, please enter the email from your account here.\nPlease see the email we will then send you for further information.",
    vendor_login_info_text: "Log in with your B2B login to start with the SQlab Profiler:",
    vendor_login_b2c_notice:
        "This app serves as a tool for bicycle shops. The use by consumers (B2C customers) is not intended.",
    vendor_login_vendor_id_label: "Vendor-ID",
    vendor_login_vendor_password_forgot_info: "Please call SQlab to reset your password!",
    profile_login_iqlab_info_text:
        "This user is not protected by a password. Since version 0.10.0 these users are no longer supported. Please use the 'Forgot password?' function to request a password for this user.\n\n>>After that you have to restart the app.<<",
    login_no_connection: "You cannot login, because your device is not connected to the internet.",
    profile_creation_timeout: "The user could not be created. Please try again later!",
    profile_creation_email_exists: "A user with this e-mail already exists.",
    profile_creation_no_connection: "You cannot create a new user, because your device is not connected to the internet.",
    profile_creation_error: "An error occurred while creating the user. Please try again later!",
    stats_tab: "Statistics",
    ranking_tab: "Ranking",
    provision_tab: "Commission",
    stats_headline: "Personal statistics",
    stats_number_questionnaires: "Number of your questionnaires",
    stats_number_sold_products: "Your products sold",
    stats_duration_median: "Average duration of your questionnaires",
    stats_duration_minutes: "min",
    stats_duration_total: "Total duration of your questionnaires",
    stats_provision_disclaimer: "We are in the beta phase.\nNo commission is paid!",
    stats_provision_boost: "Your commission per questionnaire is increased to {1}{2} until {0}",
    stats_provision_boost_title: "Commission Boost",
    stats_provision_questionnaire_provision_legend: "Advisor Commission",
    stats_provision_online_provision_legend: "Sales Commission",
    stats_provision_personal_contribution_legend: "Personal Contribution",
    stats_provision_team_contribution_legend: "Team Contribution",
    stats_provision_personal_contribution_headline: "Personal Contribution",
    stats_provision_team_headline: "Team Commission",
    stats_commission_info:
        "Advisor commission is only paid if all product groups are queried and the customer receives the results in printed form or by e-mail.",
    stats_provision_net: "net: {0}",
    stats_provision_no_individual_contribution: "You have not earned a commission yet.",
    stats_provision_no_team_contribution:
        "Your team has not earned a commission yet. Commissions are earned for completed surveys and purchases in the online store.",
    stats_provision_error: "The commission data could not be fetched from the server. Please try again in a moment!",
    ranking_no_profiles_error: "Unfortunately there are no users.",
    ranking_score: "Score:",
    historic_table_id: "ID",
    historic_table_time: "Date",
    historic_table_customer: "Customer",
    historic_table_profile: "User",
    historic_search_result_text: "{0} questionnaires found for this search",
    historic_no_questionnaires_available:
        "No questionnaires have yet been carried out with this user. As soon as questionnaires have been carried out, they will be displayed here.",
    historic_no_questionnaires_found: "No questionnaires could be found for this search query.",
    historic_no_connection_overview_error:
        "Questionnaire data cannot be called up because there is currently no connection to the Internet.",
    historic_timeout_overview_error:
        "Connection timed out.\nQuestionnaire data cannot currently be accessed. Try again later.",
    historic_server_overview_error:
        "Questionnaire data cannot currently be retrieved due to a server error. Please try again later.",
    historic_no_connection_detail_error:
        "Detailed data on this questionnaire cannot be fetched because there is currently no connection to the Internet.",
    historic_timeout_detail_error:
        "Connection timed out.\nDetailed data on this questionnaire cannot currently be fetched. Try again later.",
    historic_server_detail_error:
        "Detailed data on this questionnaires cannot currently be fetched due to a server error. Please try again later.",
    historic_no_connection_products_error:
        "Article recommendations for this questionnaire cannot be fetched because there is currently no connection to the Internet.",
    historic_timeout_products_error:
        "Connection timed out.\nArticle recommendations for this questionnaire are currently unavailable. Try again later.",
    historic_server_products_error:
        "Article recommendations for this questionnaire cannot currently be retrieved due to a server error. Please try again later.",
    historic_selected_product_groups_label: "Selected Product Groups",
    product_type_saddle: "Saddle",
    product_type_grips: "Grips",
    product_type_handlebar_tape: "Handlebar Tape",
    product_type_innerbarends: "Innerbarends",
    product_type_handlebar: "Handlebar",
    product_type_stem: "Stem",
    product_type_pedals: "Pedals",
    product_type_insoles: "Insoles",
    product_type_jerseys: "Jerseys",
    product_type_shorts: "Shorts",
    product_type_gloves: "Gloves",
    product_type_shoes: "Shoes",
    product_type_knee_pads: "Knee Pads",
    product_type_elbow_pads: "Elbow Pads",
    product_type_helmet: "Helmet",
    product_type_pants: "Pants",
    product_type_bicycle: "Bicycle",
    product_type_socks: "Socks",
    product_type_unknown: "Unknown",
    question_label_bicycle_type: "Bicycle type",
    question_label_e_bike: "E-Bike",
    question_label_sitbone_distance: "Sitbone distance",
    question_label_sitting_position: "Sitting position",
    question_label_saddle_width: "Saddle width",
    question_label_riding_frequency: "Riding frequency",
    question_label_riding_time: "Riding time",
    question_label_bodyweight: "Bodyweight",
    question_label_gender: "Gender",
    question_label_contact_point_pelvis: "Problems pelvis",
    question_label_grip_size: "Grip size",
    question_label_contact_point_hand: "Problems hand",
    question_label_foot_type: "Foot type",
    question_label_leg_axis: "Leg axis",
    question_label_foot_position: "Foot position",
    question_label_shoe_size: "Shoe size",
    question_label_contact_point_foot: "Problems foot",
    question_label_shoulder_width_handlebar: "Shoulder width handlebar",
    question_label_bodyheight: "Body height",
    question_label_bodyheight_bicycle: "Body height bicycle",
    question_label_clothing_size: "Clothing size",
    question_label_pedal_size: "Pedal size",
    question_label_insoles_size: "Insoles size",
    question_label_gloves_size: "Gloves size",
    question_label_unknown: "Unknown",
    pdf_value_missing: "Missing",
    pdf_title: "Article Recommendations",
    pdf_subtitle: "SQlab Profiler",
    pdf_description: "Recommendations {0} on {1}",
    pdf_for_aux: "for",
    pdf_expert: "Assistant",
    pdf_article_column_title: "Art.",
    pdf_type_column_title: "Type",
    pdf_brand_column_title: "Brand",
    pdf_product_column_title: "Product",
    pdf_fit_column_title: "Fit",
    pdf_bold_note: "Note: The best fitting article per type is bold",
    hasura_user_repository_createProfile_timeout_exception:
        "No user can be created at the moment.\nPlease try again later!",
    hasura_user_repository_loginWithToken_timeout_exception:
        "The login is currently unavailable.\nPlease try again later.",
    hasura_user_repository_requestOneTimePassword_timeout_exception:
        "The password could not be reset.\nPlease try again later!",
    hasura_user_repository_resetPassword_timeout_exception: "The password could not be changed.\nPlease try again later!",
    hasura_ranking_repository_getRankingData_timeout_exception: "Ranking could not be retrieved. Connection timeout.",
    hasura_settings_repository_getManufacturers_timeout_exception:
        "The bike manufacturer list cannot be retrieved.\nPlease try again later.",
    hasura_settings_repository_updateExcludedBikeManufacturers_timeout_exception:
        "The bike manufacturer list cannot be updated.\nPlease try again later.",
    hasura_statistics_repository_getStatisticsNumbers_timeout_exception:
        "The number of surveys cannot be retrieved. Connection timeout.",
    hasura_statistics_repository_updateCommission_timeout_exception:
        "The commission cannot be updated.\nPlease try again later.",
    navigation_save: "Save",
    length_input_widget_value_toosmall_text: "The entered value is too small.",
    length_input_widget_value_toobig_text: "The entered value is too big.",
    gripping_distance_question_gripWidth_label: "Grip width",
    gripping_distance_question_handWidth_label: "Hand width",
    body_measurements_question_question_stepLength: "Crotch length",
    body_measurements_question_question_shoulderWidth: "Shoulder width",
    toast_questionnaire_bodymeasurement_cant_continue_info:
        "Please enter measurements for the body or do an image recognition.",
    body_measurements_question_question_armLength: "Arm length",
    sitbone_distance_question_goToNextPageNotAllowedText:
        "The entered value is either to small or too big. Please adjust your entry",
    numpad_errorhint_too_small: "The value is too small.",
    numpad_errorhint_too_big: "The value is too big.",
    done_label: "Done",
    save_label: "Save",
    none_label: "None",
    signed_label: "Signed",
    not_signed_label: "Not signed",
    questionnaire_summary_no_email: "No E-Mail",
    questionnaire_summary_no_name: "No name",
    ebike_answer_yes: "Yes E-Bike",
    ebike_answer_no: "No E-Bike",
    questionnaire_summary_edit_question: "Edit",
    recommended_products_bike_size: "Framesize",
    recommended_products_stack: "Stack",
    recommended_products_reach: "Reach",
    recommended_products_info_label_stack_and_reach: "Stack and Reach",
    recommended_products_str_quotient: "StR Quotient",
    recommended_products_category_bike_profile: "Bike Profile",
    cv_error_code_100: "Paper is too small",
    cv_error_code_101: "Too few points detected",
    cv_error_code_103: "Unrealistic distance",
    cv_error_code_104: "Picture was taken from an improper angle",
    cv_error_code_105: "Bad light conditions",
    cv_error_code_106: "Hand not stretched enough",
    cv_error_code_107: "Body is too small",
    cv_error_code_400: "No paper detected",
    cv_error_code_401: "Too few points detected",
    cv_error_code_402: "Not enought points for proper clustering detected",
    cv_error_code_403: "Not enough points per cluster",
    cv_error_code_404: "Image resolution is too low",
    cv_error_code_405: "Unsupported image format",
    cv_error_code_406: "Image resolution is too high",
    cv_error_code_407: "Too many hands detected",
    cv_error_code_408: "No hand detected",
    cv_error_code_409: "Thumb not stretched",
    cv_error_code_410: "No reference object detected",
    cv_error_code_411: "Hand not detected properly",
    cv_error_code_412: "No body detected",
    cv_error_code_413: "Picture of body not taken properly",
    cv_error_code_414: "Too many points detected",
    cv_error_code_419: "Hand is not parallel to device",
    cv_error_code_420: "Fingers are not stretched",
    cv_error_code_500: "Unexpected error",
    cv_error_code_600: "Validation error",
    cv_error_code_700: "Connection error",
    cv_error_code_800: "Unknown error",
    cv_error_code_unknown: "Unknown error",
    cv_suggestion_code_100: "Make sure the paper makes up the bulk of the image.",
    cv_suggestion_code_101: "Create better lighting conditions and make sure that there are enough holes in the paper.",
    cv_suggestion_code_103: "Create better lighting conditions and avoid reflections on the sheet.",
    cv_suggestion_code_104: "Take the picture directly from above frontally.",
    cv_suggestion_code_105: "Create better lighting conditions.",
    cv_suggestion_code_106: "Stretch the thumb further away from the hand.",
    cv_suggestion_code_107: "The body should be a larger part of the image.",
    cv_suggestion_code_400:
        "The paper should make up the majority of the image. Be sure to photograph the white back of the sit bone paper on a flat and non-glowing surface.",
    cv_suggestion_code_401:
        "Be sure to photograph the white back of the sit bone paper on a flat and non-glowing surface.",
    cv_suggestion_code_402:
        "Be sure to photograph the white back of the sit bone paper on a flat and non-glowing surface.",
    cv_suggestion_code_403:
        "Be sure to photograph the white back of the sit bone paper on a flat and non-glowing surface.",
    cv_suggestion_code_404: "Use a higher image resolution.",
    cv_suggestion_code_405: "Use only JPG or PNG files for image recognition.",
    cv_suggestion_code_406: "Use a smaller image resolution.",
    cv_suggestion_code_407: "Make sure that only one hand is in the picture.",
    cv_suggestion_code_408: "Make sure that the image shows a hand.",
    cv_suggestion_code_409: "Stretch the thumb through.",
    cv_suggestion_code_410:
        "Make sure that the reference sticker is completely visible in the image, is not covered and lies on a flat surface.",
    cv_suggestion_code_411: "Make sure that the hand is clearly visible.",
    cv_suggestion_code_412: "Make sure that the whole body is clearly visible.",
    cv_suggestion_code_413: "Make sure that the whole body is clearly visible.",
    cv_suggestion_code_414: "Ensure that there are two clusters of points on the sit bone paper used.",
    cv_suggestion_code_419: "Make sure that the hand is tilted correctly and is parallel to the device",
    cv_suggestion_code_420: "Make sure that fingers are stretched",
    cv_suggestion_code_500: "Unknown error",
    cv_suggestion_code_600: "Please consult the debug logs",
    cv_suggestion_code_800: "Make sure that the latest version of the app is installed on your device.",
    cv_suggestion_code_unknown: "Make sure that the latest version of the app is installed on your device.",
    camera_device_frontal_info: "Please hold the device head-on towards the customer.",
    camera_device_horizontal_info: "Please hold the device horizontally.",
    camera_sitbones_title: "Sitbones",
    camera_sitbones_orientation_help: "Please point the device at the used\n sit bone paper!",
    camera_sitbones_info: "Aim the camera at the used\n sit bone paper and press the shutter button!",
    camera_handmark_title: "Handmark",
    camera_handmark_orientation_help: "Please point the device at the customer's hand!",
    camera_handmark_info:
        "Point the camera at the customer's hand.\nThe reference sticker must be completely in the picture!",
    camera_crotch_title: "Body Measurements",
    camera_crotch_orientation_help: "Please point the device at the customer and keep it vertical!",
    camera_crotch_info:
        "Point the device at the customer and press the shutter release button! The customer should not wear shoes and spread his arms and legs a little.",
    camera_kneeangle_title: "Knee Angle",
    camera_kneeangle_orientation_help: "",
    camera_kneeangle_info: "",
    scan3d_repeat_button: "Take another image",
    experimental_title: "Experimental Features",
    experimental_title_bikefitting: "Bike Fitting",
    experimental_title_hand_scan3d: "3D Hand Scan",
    experimental_title_hand_scan: "2D Hand Scan",
    experimental_title_foot_scan: "Foot Scan",
    experimental_title_crotch_scan: "Crotch Scan",
    experimental_title_sitbones_scan: "Sitbones Scan",
    error_message_title: "Something went wrong",
    error_message_content: "Try one more time or contact an employee.",
    answers_sending_error_title: "The answers could not be sent",
    answers_sending_error_content: "Try one more time or contact an employee.",
    non_optional_warning_title: "Please choose an answer option",
    bike_type: "Bike Type",
    bike_title: "Bike",
    bike_profile: "Bike Profile",
    frame_size: "Frame Size",
    ride_height: "Ride Height",
    sitBones: "Sit Bones",
    bodyHeight: "Body Height",
    bodyMeasurements: "Body Measurements",
    gripSize: "Grip Size",
    shoeSize: "Shoe Size"
};
