export enum ArticleGroups {
    pelvis = "pelvis",
    hand = "hand",
    foot = "foot",
    clothing = "clothing",
    bike = "bike_profile",
}

export const ArticleGroupsMatchingWithHasura: { [key: string]: string } = {
    pelvis: "pelvis",
    hand: "hand",
    foot: "foot",
    clothing: "clothing",
    bike_profile: "bike",
}


export enum BicycleTypeEnum {
    triathlon = "triathlon_bike",
    racingBike = "racing_bike",
    gravel = "gravel",
    mountainBikeCrossCountry = "mtb_xc",
    mountainBikeAllMountain = "mtb_all_mountain",
    trekkingCross = "trekking_cross",
    trekkingComfort = "trekking_comfort",
    cityComfort = "city_comfort",
}

export enum EBike {
    eBikeYes = "ebike_yes",
    eBikeNo = "ebike_no",
}

export enum RidingDuration {
    lessThan45Minutes = "lessThan45min",
    lessThan2Hours = "between45minAnd2h",
    moreThan2Hours = "moreThan2h",
}

export enum RidingFrequency {
    severalTimesPerWeek = "several_times_a_week",
    oncePerWeek = "one_time_a_week",
    everyTwoWeeks = "every_two_weeks",
    everyFourWeeks = "every_four_weeks",
}

export enum FootPosition {
    vFootPosition = "v",
    straightFootPosition = "straight",
}

export enum FootProblems {
    numbToes = "numb_toes",
    kneeDiscomfort = "knee_discomfort",
}

export enum FootType {
    archesFootType = "arches_foot",
    normalFootType = "normal",
    archedFootType = "arched_foot",
    flatFoodType = "flat_foot",
}

export enum HandProblems {
    numbThumbAndIndexAndMiddleFinger = "numb_thumb_index_and_middle_finger",
    numbLittleAndRingFinger = "numb_little_and_ring_finger",
    wristProblem = "wrist",
    upperBackProblem = "upper_back",
}

export enum LegAxis {
    oLegs = "o_legs",
    straightLegs = "straight_legs",
    xLegs = "x_legs",
}

export enum PelvicProblems {
    numbness = "numbness",
    sitBonesProblem = "sitbones",
    coccyx = "coccyx",
    pubicBoneProblem = "pubic_bone",
    prostateProblem = "prostate",
    lowerBackProblem = "lower_back",
    heatSweatProblem = "heat_sweat",
    pelvicMisalignment = "pelvic_malalignment",
}

export enum SeatPosition {
    triathlonSeatPosition = "triathlon",
    sportiveSeatPosition = "sportive",
    moderateSeatPosition = "moderate",
    slightlyBentSeatPosition = "slightly_bent",
    uprightSeatPosition = "upright",
}

export enum Sex {
    male = "male",
    female = "female",
    diverse = "diverse",
}
