import {styled} from "@mui/material/styles";
import Button, {ButtonProps} from "@mui/material/Button";

interface SQButtonProps extends ButtonProps {
  selected: boolean;
}

export function SQToggleButton(props: SQButtonProps) {
  const StyledButton = styled(Button, {
    shouldForwardProp(propName: PropertyKey) {
      return propName !== "selected";
    },
  })<SQButtonProps>(({ selected, theme }) => {
    let borderProps = selected
      ? {
          color: theme.palette.primary.main,
          border: "4px solid",
          borderColor: theme.palette.primary.main,
        }
      : {
          color: theme.palette.secondary.main,
          border: "2px solid",
          margin: "2px",
          borderColor: theme.palette.secondary.main,
        };

    return {
      ...borderProps,
      textTransform: "none",
      borderRadius: 15,
      flexGrow: 1,
      fontSize: "2.4rem",
      height: '100%',
    };
  });

  return (
    <StyledButton {...props} sx={{ boxShadow: props.selected ? 4 : 0 }}>
      {props.children}
    </StyledButton>
  );
}
