import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import {useNavigate} from "react-router";
import {useStoreDispatch, useStoreSelector} from "../store/hooks";
import {selectIsCreating, startQuestionnaire} from "../store/questionnaireSlice";
import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import ModalTemplate from "../components/general/ModalTemplate";

export default function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useStoreDispatch();
  const isCreating = useStoreSelector(selectIsCreating);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  async function handleStartQuestionnaire() {
    try {
      await dispatch(startQuestionnaire()).unwrap();
      navigate({ pathname: "/questionnaire", search: searchParams.toString() });
    } catch (e) {
      setShowErrorModal(true);
    }
  }

  return (
    <Container sx={{ display: "flex", justifyContent: "center", py: 5 }}>
      <Stack alignItems="center">
        <Button variant="outlined" onClick={handleStartQuestionnaire} disabled={isCreating}>
          <Typography fontSize={40} sx={{ px: 7, py: 3, display: "flex", alignItems: "center", gap: 3 }}>
            <span>Befragung Starten</span>
            {isCreating ? <CircularProgress></CircularProgress> : null}
          </Typography>
        </Button>
        <Typography color="secondary" variant="h5" pt={4} textAlign={"center"} width="66%">
          Zum Abschließen der Befragung ist ein iPad (iPad Pro ab 2018 und iOS 13 oder neuer) mit der SQlab Profiler App
          notwendig.
        </Typography>
        <Button variant="text" onClick={() => setShowInfoModal(true)}>
          Mehr Informationen
        </Button>
      </Stack>
      <Dialog open={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <DialogTitle>Befragung konnte nicht gestartet werden</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Die Befragung konnte nicht gestartet werden. Probieren Sie es erneut oder wenden Sie sich an einen
            Mitarbeiter.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowErrorModal(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <ModalTemplate open={showInfoModal}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h4">Informationen zum SQlab Profiler</Typography>
          <Button onClick={() => setShowInfoModal(false)}>Schließen</Button>
        </Stack>
        <Typography variant="h5">Was ist der SQlab Profiler?</Typography>
        <p>
          Der SQlab Profiler ist eine App, die als unterstützendes Verkaufstool für den Fachhandel dient. Durch eine
          gezielte Befragung und digitaler Körpervermessung mit einer Bilderkennungssoftware, können jedem Kunden,
          angepasst auf seine individuellen Bedürfnisse, u. A. Fahrräder, Zubehör und Bekleidung empfohlen werden.
        </p>
        <p>
          <b>Weitere Informationen finden Sie auf https://profiler.bike</b>
        </p>
        <Typography variant="h5">Wer kann den SQlab Profiler benutzen?</Typography>
        <p>
          Der SQlab Profiler steht “exklusiv” jedem SQlab Fachhändler mit einem aktiven Partnervertrag zur Verfügung.
          Aktuell steht dieser Service nur in Deutschland als Beta-Version zur Verfügung. In naher Zukunft wird die
          Nutzung auch für alle unsere internationalen SQlab Händler möglich sein.
        </p>
        <Typography variant="h5">Welche Vorteile habe ich als Benutzer des SQlab Profilers?</Typography>
        <p>
          Im Fahrradfachhandel steht nach wie vor das individuelle Verkaufsgespräch und die richtige Beratung im
          Vordergrund. Der SQlab Profiler unterstützt den Händler dabei, schnell und einfach, ein zielgerichtetes und
          verkaufsförderndes Gespräch mit dem Kunden zu führen.
        </p>
        <p>
          Auch als Neueinsteiger ist es möglich mit dem SQlab Profiler Kunden zielgerichtet, durch eine einfache und
          schnelle Handhabung, einer digitalen Vermessung und einer sehr detaillierten Produktempfehlung zu beraten.
        </p>
        <p>
          Ein Provisions-System mit einer quartalsweisen Vergütung für jeden Mitarbeiter, welcher den Profiler nutzt,
          ist auch vorhanden. Für weitere Informationen zu den Provisionsmodell kann sich an den jeweiligen
          Kundenbetreuer gewendet werden.
        </p>
        <Typography variant="h5">Was wird benötigt, um den SQlab Profiler zu benutzen?</Typography>
        <p>
          Um den SQlab Profiler nutzen zu können, wird ein aktiver SQlab Partner Vertrag benötigt, sowie mindestens ein
          iPad und eine stabile Internetverbindung.
        </p>
        <Typography variant="h5">Welche iPad Modelle sind unterstützt? </Typography>
        <p>
          Wir unterstützen alle iPad Pro Modelle seit 2018 mit iOS 13 (und neuer).
          <ul>
            <li>12.9 Zoll: 3. Generation - 5. Generation</li>
            <li>11 Zoll: 1. Generation - 3. Generation</li>
          </ul>
        </p>
        <Typography variant="h5">Wie funktioniert der Anmeldeprozess?</Typography>
        <p>
          Für eine erfolgreiche Nutzung des SQlab Profilers ist es zunächst wichtig, das Anmeldeformular auf der Website
          auszufüllen: profiler.bike. Nach erfolgreicher Anmeldung wird der SQlab Fachhändler über die weiteren Schritte
          per Email informiert. Dieser Service steht ausschließlich für Fachhändlern mit einem aktiven SQlab Partner
          Vertrag zur Verfügung.
        </p>
      </ModalTemplate>
    </Container>
  );
}
