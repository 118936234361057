import {Container, Typography} from "@mui/material";
import SuggestionGroup from "../components/suggestions/SuggestionGroup";
import {Article_Type_Enum, GetRecommendedProductsQuery, useGetRecommendedProductsQuery} from "../generated/graphql";
import {Delete, Outbox, Print} from "@mui/icons-material";
import {theme} from "../theme";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {BicycleType} from "../data/BicycleType";
import BikeInfosSNR from "../components/suggestions/BikeInfosSNR";
import {countryCodeFromLanguage} from "../translations/i18n";

const ARTICLE_TYPE_ORDER: `${Article_Type_Enum}`[] = [
    "bike",
    "saddle",
    "handle",
    "innerbarends",
    "handlebars",
    "handlebar_tape",
    "stem",
    "pedal",
    "insole",
    "gloves",
    "jersey",
    "shorts",
    "pants",
    "clothing",
    "accessory",
    "shoes",
    "helmet",
    "elbow_pads",
    "knee_pads",
    "socks"
];
export type Recommendation = GetRecommendedProductsQuery['recommendation_own_articles'][0];
export default function Results() {
    const {t, i18n} = useTranslation();

    const questionnaireId = useParams().id ?? '';
    const countryCode = countryCodeFromLanguage(i18n.language);
    let {data} = useGetRecommendedProductsQuery({variables: {answer_id: questionnaireId, language: countryCode}});
    const bikeRecommendation = data?.customer_answer[0]?.article_groups.includes('bike')

    const recommendations: any = data?.recommendation_own_articles ?? [];

    const groupedData: Record<string, Recommendation[]> = {};

    const bicycleTypeId: string = data?.customer_answer[0]?.bicycle_type_id as string;
    const stepLength: number = data?.customer_answer[0]?.step_length as number;

    const bicycleType = BicycleType.byInternalId(bicycleTypeId);

    ARTICLE_TYPE_ORDER.forEach((type) => {


        const articlesWithType = recommendations.filter((recommendation: Recommendation) => {
            return recommendation.article_informaton_i18n[0].article_type === type;
        });
        if (articlesWithType.length !== 0) {
            groupedData[type] = articlesWithType;
        }
    });

    //TODO: Enable edit button + create edit view (not necessary)
    //TODO: Create Product views
    //TODO: create correct print view (not necessary)
    //TODO: enable delete button (not necessary)
    //TODO: enable mail button (not necessary)
    //TODO: fix/ enable backend requests to receive data from backend and not from json
    const print = () => {
        window.print();
    };

    return (
        <Container className="" sx={{mt: 10, flexGrow: 1, textAlign: "center"}}>
            <div style={{display: "flex", justifyContent: "center", flexGrow: "3"}}>
                <Typography variant="h2" style={{margin: "auto"}}>
                    {t("questionnaire_finish_headline")}
                </Typography>
                {
                    //<Typography variant="h4" color={'blue'}><Edit/>{t('questionnaire_summary_edit_question')}</Typography>
                }
            </div>

            <div style={{display: "flex", justifyContent: "center"}}>
                <div
                    style={{
                        margin: "0.5em",
                        // cursor: "pointer",
                        color: theme.palette.secondary.main,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Outbox/>
                    E-Mail
                </div>
                <div
                    style={{
                        margin: "0.5em",
                        // cursor: "pointer",
                        color: theme.palette.secondary.main,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    // onClick={print}
                >
                    <Print/>
                    Drucken
                </div>
                <div
                    style={{
                        margin: "0.5em",
                        // cursor: "pointer",
                        color: theme.palette.secondary.main,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Delete/>
                    Löschen
                </div>
            </div>
            {(bicycleType && bikeRecommendation) ?
                <BikeInfosSNR bicycleType={bicycleType} stepLength={stepLength}/> : null}
            {Object.entries(groupedData).map(([key, value]) => (
                <SuggestionGroup title={key} items={value}/>
            ))}
            <footer style={{color: "lightgrey"}}>* Bilder sind in der Regel urheberrechtlich geschützt.</footer>
        </Container>
    );
}
