import {
    ArticleGroups,
    BicycleTypeEnum,
    EBike,
    FootPosition,
    FootProblems,
    FootType,
    HandProblems,
    LegAxis,
    PelvicProblems,
    RidingDuration,
    RidingFrequency,
    SeatPosition,
    Sex,
} from "./AnswerEnums";
import {BicycleType} from "./BicycleType";

export interface AnswerConfig {
    text: string;
    value?: number | string;
    image?: string;
}

export interface QuestionConfig {
    matrixId: number;

    questionId: string;

    questionHeadline: string;

    helpTextDocumentName: string;

    productGroups: Array<string>;

    productCategories: Array<string>;

    canSelectMultipleOptions: boolean;

    displayPlaceholder: boolean;

    defaultValue: (answer: any) => (string | number)[];

    answers: AnswerConfig[];

    filterAnswer?: (answers: Record<string, (string | number)[]>) => (answer: AnswerConfig) => boolean;

    optional: boolean;
}

const GenderSpecificConfig: Record<Sex, any> = {
    male: {
        standardHeight: 180,
        standardShoeSize: 42,
        hasPubicBone: false,
        hasProstate: true,
    },
    female: {
        standardHeight: 165,
        standardShoeSize: 39,
        hasPubicBone: true,
        hasProstate: false,
    },
    diverse: {
        standardHeight: 173,
        standardShoeSize: 40,
        hasPubicBone: true,
        hasProstate: true,
    },
};

function enumToAnswers(enumeration: any): AnswerConfig[] {
    return (
        Object.values(enumeration).map((v) => ({
            text: v as string,
        })) ?? []
    );
}

function enumToAnswersWithImages(enumeration: any, imgPrefix: string): AnswerConfig[] {
    return (
        Object.values(enumeration).map((v) => ({
            text: v as string,
            image: `${imgPrefix}/${v as string}.jpg`,
        })) ?? []
    );
}

export const QuestionnaireConfig: Record<string, QuestionConfig> = {
    article_groups: {
        matrixId: -1,
        questionId: "article_groups",
        questionHeadline: "settings_select_product_groups_headline",
        helpTextDocumentName: "",
        productGroups: Object.values(ArticleGroups),
        productCategories: [ArticleGroups.pelvis,
            ArticleGroups.hand,
            ArticleGroups.foot,
            ArticleGroups.clothing,
            ArticleGroups.bike],
        canSelectMultipleOptions: true,
        displayPlaceholder: true,
        defaultValue: () => Object.values(ArticleGroups),
        answers: enumToAnswers(ArticleGroups),
        optional: false,
    },
    sex: {
        matrixId: 9,
        questionId: "sex",
        questionHeadline: "sex_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.clothing, ArticleGroups.bike],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.foot, ArticleGroups.clothing, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [Sex.male],
        answers: [{text: Sex.male}, {text: Sex.female}],
        optional: false,
    },
    bicycle_type: {
        matrixId: 1,
        questionId: "bicycle_type",
        questionHeadline: "bicycle_type_question_question_text",
        helpTextDocumentName: "bike_types",
        productGroups: [
            ArticleGroups.pelvis,
            ArticleGroups.hand,
            ArticleGroups.foot,
            ArticleGroups.clothing,
            ArticleGroups.bike,
        ],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.foot, ArticleGroups.clothing, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [BicycleType.MTB_ALL_MOUNTAIN.internalId],
        answers: enumToAnswers(BicycleTypeEnum),
        optional: false,
    },
    e_bike: {
        matrixId: 2,
        questionId: "e_bike",
        questionHeadline: "ebike_question_question_text",
        helpTextDocumentName: "",
        productGroups: [
            ArticleGroups.pelvis,
            ArticleGroups.hand,
            ArticleGroups.foot,
            ArticleGroups.clothing,
            ArticleGroups.bike,
        ],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.foot, ArticleGroups.clothing, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: (answers) => {
            if (
                [BicycleTypeEnum.triathlon, BicycleTypeEnum.racingBike, BicycleTypeEnum.gravel].includes(
                    answers["bicycle_type"]
                )
            ) {
                return [EBike.eBikeNo];
            } else {
                return [EBike.eBikeYes];
            }
        },
        answers: enumToAnswers(EBike),
        optional: false,
    },
    driving_frequency: {
        matrixId: 6,
        questionId: "driving_frequency",
        questionHeadline: "driving_frequency_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.bike],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [RidingFrequency.oncePerWeek],
        answers: enumToAnswers(RidingFrequency),
        optional: false,
    },
    driving_duration: {
        matrixId: 7,
        questionId: "driving_duration",
        questionHeadline: "driving_duration_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.clothing, ArticleGroups.bike],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.clothing, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [RidingDuration.lessThan2Hours],
        answers: enumToAnswers(RidingDuration),
        optional: false,
    },
    seat_position: {
        matrixId: 4,
        questionId: "seat_position",
        questionHeadline: "seat_position_question_question_text",
        helpTextDocumentName: "sitting_positions",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.bike],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.hand, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [SeatPosition.moderateSeatPosition],
        answers: enumToAnswersWithImages(SeatPosition, "/images/seatPosition"),
        optional: false,
    },
    weight: {
        matrixId: 8,
        questionId: "weight",
        questionHeadline: "weight_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.clothing, ArticleGroups.bike],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.bike],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [80],
        answers: [
            {text: "weight_question_sub60_label", value: 60},
            {text: "weight_question_sub70_label", value: 70},
            {text: "weight_question_sub80_label", value: 80},
            {text: "weight_question_sub90_label", value: 90},
            {text: "weight_question_sub100_label", value: 100},
            {text: "weight_question_sub110_label", value: 110},
            {text: "weight_question_sub120_label", value: 120},
            {text: "weight_question_plus120_label", value: 121},
        ],
        optional: false,
    },
    pelvic_problems: {
        matrixId: 10,
        questionId: "pelvic_problems",
        questionHeadline: "pelvic_problems_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.pelvis, ArticleGroups.clothing],
        productCategories: [ArticleGroups.pelvis, ArticleGroups.clothing],
        canSelectMultipleOptions: true,
        displayPlaceholder: true,
        defaultValue: () => [],
        answers: enumToAnswers(PelvicProblems),
        optional: true,
        filterAnswer(answers) {
            const sex = (answers["sex"]?.[0] as Sex) ?? Sex.male;
            const genderConfig = GenderSpecificConfig[sex];
            return (answer: AnswerConfig) => {
                if (answer.text === PelvicProblems.prostateProblem) {
                    return genderConfig.hasProstate;
                }
                if (answer.text === PelvicProblems.pubicBoneProblem) {
                    return genderConfig.hasPubicBone;
                }
                return true;
            };
        },
    },
    hand_problems: {
        matrixId: 12,
        questionId: "hand_problems",
        questionHeadline: "hand_problem_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.hand],
        productCategories: [ArticleGroups.hand],
        canSelectMultipleOptions: true,
        displayPlaceholder: true,
        defaultValue: () => [],
        answers: enumToAnswers(HandProblems),
        optional: true,
    },
    leg_axis: {
        matrixId: 14,
        questionId: "leg_axis",
        questionHeadline: "leg_axis_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.foot],
        productCategories: [ArticleGroups.foot],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [LegAxis.straightLegs],
        answers: enumToAnswersWithImages(LegAxis, "/images/legAxis"),
        optional: false,
    },
    footType: {
        matrixId: 13,
        questionId: "foot_type",
        questionHeadline: "foot_type_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.foot],
        productCategories: [ArticleGroups.foot],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [FootType.normalFootType],
        answers: enumToAnswersWithImages(FootType, "/images/footType"),
        optional: false,
    },
    foot_position: {
        matrixId: 15,
        questionId: "foot_position",
        questionHeadline: "foot_position_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.foot],
        productCategories: [ArticleGroups.foot],
        canSelectMultipleOptions: false,
        displayPlaceholder: true,
        defaultValue: () => [FootPosition.vFootPosition],
        answers: enumToAnswersWithImages(FootPosition, "/images/footPosition"),
        optional: false,
    },
    foot_problems: {
        matrixId: 17,
        questionId: "foot_problems",
        questionHeadline: "foot_problem_question_question_text",
        helpTextDocumentName: "",
        productGroups: [ArticleGroups.foot],
        productCategories: [ArticleGroups.foot],
        canSelectMultipleOptions: true,
        displayPlaceholder: true,
        defaultValue: () => [],
        answers: enumToAnswers(FootProblems),
        optional: true,
    },
};