import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {ErrorMessage} from "../../pages/Questionnaire";

interface ErrorMessageState{
    errorMessage: ErrorMessage,
    setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
}
function WarningDialog({errorMessage: {title, content, show}, setErrorMessage}: ErrorMessageState) {
    return (
        <Dialog open={show}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() =>  setErrorMessage({title, content, show: false})} autoFocus>
                Ok
              </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WarningDialog;