import {theme} from "../../theme";
import {useState} from "react";
import ModalTemplate from "../general/ModalTemplate";
import {Button, Link, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Recommendation} from "../../pages/Results";

export default function SuggestedItem(props: { item: Recommendation }) {
  const { t } = useTranslation();
  // i18n.language
  const [showDetail, setShowDetail] = useState(false);
  function setModalVisible() {
    setShowDetail(true);
  }
  function hideModal() {
    setShowDetail(false);
  }

  const item = props.item;
  const article = item.article_informaton_i18n[0];
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100%", cursor: "pointer" }}
        onClick={setModalVisible}
      >
        <p style={{ color: theme.palette.secondary.main }}>{article.brand}</p>
        <p style={{ fontSize: "1.5em" }}>
          <b>{article.name?.toUpperCase() ?? '-'}</b>
        </p>
        <img src={article.images[0]} style={{ width: "100%", margin: "auto" }} alt="Produktbild" />
        <p style={{ color: theme.palette.secondary.main, marginTop: "auto" }}>{article.size}</p>
      </div>

      <ModalTemplate open={showDetail} style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
        <Button style={{ alignSelf: "end" }} onClick={hideModal}>
          {t("navigation_close")}
        </Button>
        <div style={{ display: "flex", gap: "3em" }}>
          <div>
            <Typography variant={"h5"}>
              <b>{article.name}</b>
            </Typography>
            <img
              src={article.images[0]}
              style={{ width: "100%", margin: "auto", maxWidth: "500px" }}
              alt="produktbild"
            />
            <Typography variant="h6">{t("questionnaire_product_description_headline")}</Typography>
            <p style={{ overflow: "auto" }}>{article.description}</p>
            <Typography variant={"h5"} color={"primary"}>
              <Link href={article.shop_url ?? ""} style={{ textDecoration: "none" }}>
                {t("questionnaire_product_open_online_shop")}
              </Link>
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
              <p style={{ marginBottom: "0em" }}>{t("questionnaire_product_size_headline")}</p>
              <Typography variant={"h5"} color={"primary"} style={{ marginTop: "0em", marginBottom: "1em", width:'max-content'}}>
                {article.size}
              </Typography>
            </div>
            <Table>
              <TableBody>
                {item.reasons.map((reason: any) => (
                    <TableRow key={reason.question}>
                      <TableCell component="th" scope="row">
                        {reason.question}
                      </TableCell>
                      <TableCell align="right">{Math.round(reason.fitting)}%</TableCell>
                    </TableRow>
                ))}
              </TableBody>
          </Table>
          <Typography
              variant={"h5"}
              color={"primary"}
              sx={{ alignSelf: "end" }}
          >{`Fitting: ${item.fitting}%`}</Typography>
        </div>
        </div>
      </ModalTemplate>
    </>
  );
}
