/**
 * Stack and Reach Data
 * Holds information about common bicycles of a certain type for set seat height
 *
 * @param stackMin Minimal stack value of that bicycle type
 * @param stackMax Maximal stack value of that bicycle type
 * @param reachMin Minimal reach value of that bicycle type
 * @param reachMax Maximal reach value of that bicycle type
 * @param sizeString Size of that bicycle type (S,M,L) as String
 * @param frameCm Framesize in centimeters of that bicycle type
 * @param frameInch Framesize in inches of that bicycle type
 *
 * @author Niclas Schümann
 */
export class SNRData {
    constructor(
        public stackMin: number,
        public stackMax: number,
        public reachMin: number,
        public reachMax: number,
        public sizeString: string,
        public frameCm: number,
        public frameInch: number
    ) {}
}
