import {Card, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {BicycleType} from "../../data/BicycleType";
import {theme} from "../../theme";

export default function BikeInfosSNR(props: {bicycleType: BicycleType, stepLength: number}) {
    const { t } = useTranslation();

    // Example: "Bicycle Type: Trekking"

    // Example: "Framesize: XXS-XS (17" | 42 cm)"
    const frameSize = Math.round(props.stepLength * props.bicycleType.srQuotient);

    // Example: "Ride Height: 79 cm"
    let rideHeight = Math.round(props.stepLength * 0.883);

    // Example: "Stack: 568 mm - 627 mm"
    // Example: "Reach: 373 mm - 390 mm"
    // Decides the appropriate stack and reach values for the given bicycle type and frame size
    const snrForBicycleType = props.bicycleType.snrMap;
    let snr = snrForBicycleType.get(frameSize);
    if (!snr) {
        snr = frameSize < 46
            ? Array.from(snrForBicycleType.values())[0]
            : Array.from(snrForBicycleType.values()).pop();
    }

    const styles = {
        containerStyle: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: '1em',
            marginRight: "3em",
            marginLeft: '3em',
            marginBottom: '1em'
        } as const,
        titleElement: {
            color: theme.palette.primary.light,
            fontWeight: 'bold',
            justifySelf: 'start',
        },
        infoElement:{
            justifySelf: 'start'
        },
        imgStyle:{
            height: '50%', // maintain aspect ratio
            width: '50%', // maintain aspect ratio
        }
    }


    const imgStyle = {
        height: 'auto',
        width: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
    };

    return (
        <div style={{ margin: "1em" }}>
            <Typography
                variant="h4"
            >
                {t('bike_title')}
            </Typography>
            <Card>
                <div style={styles.containerStyle}>
                    <img
                        src={props.bicycleType.imageUrl}
                        alt="Bike"
                        style={styles.imgStyle}
                    />
                    <div style={{display: "grid", gridTemplateColumns: '1fr 1fr', alignItems: "center"}}>
                        <Typography variant="h5" style={styles.titleElement} >{t('bike_type') + ": "}</Typography>
                        <Typography variant="h5" style={styles.infoElement}>{props.bicycleType.name}</Typography>

                        <Typography variant="h5" style={styles.titleElement}>{t('frame_size') + ": "}</Typography>
                        <Typography variant="h5" style={styles.infoElement}>{snr?.sizeString} ({snr?.frameInch}" | {snr?.frameCm} cm)</Typography>

                        <Typography variant="h5" style={styles.titleElement}>Stack: </Typography>
                        <Typography variant="h5" style={styles.infoElement}>{snr?.stackMin} mm - {snr?.stackMax} mm</Typography>

                        <Typography variant="h5" style={styles.titleElement}>Reach: </Typography>
                        <Typography variant="h5" style={styles.infoElement}>{snr?.reachMin} mm - {snr?.reachMax} mm</Typography>

                        <Typography variant="h5" style={styles.titleElement}>{t('ride_height') + ": "}</Typography>
                        <Typography variant="h5" style={styles.infoElement}>{rideHeight} cm</Typography>
                    </div>
                </div>
            </Card>

        </div>
    );
}
