export const de = {
    global_app_title: "SQlab Profiler",
    global_error_label: "Oopsie Doopsie, hier ist was schief gelaufen!",
    language_german: "Deutsch",
    language_english: "Englisch",
    measurement_metric: "Metrisch (cm/kg)",
    measurement_imperial: "Imperial (inch/lbs)",
    app_currency_symbol: "€",
    app_password_label: "Passwort",
    app_password_confirm: "Passwort wiederholen",
    app_password_reset: "Passwort zurücksetzen",
    app_password_change: "Passwort ändern",
    app_name_change: "Name ändern",
    app_password_forgot: "Passwort vergessen?",
    app_password_repeat: "Passwort wiederholen",
    app_name_repeat: "Name wiederholen",
    app_password_wrong: "Das eingegebene Passwort ist nicht korrekt.",
    app_email: "E-Mail",
    app_email_sent: "Versandt",
    app_email_label: "E-Mail Adresse",
    app_email_wrong_format:
        "Die E-Mail scheint nicht korrekt zu sein. Bitte überprüfe die angegebene E-Mail Adresse und versuche es erneut.",
    app_create_profile: "Nutzer anlegen",
    app_name_label: "Name",
    app_delete: "Löschen",
    app_print: "Drucken",
    app_search: "Suchen",
    app_scan: "Scannen",
    app_page: "Seite {0}",
    navigation_confirm: "Weiter",
    navigation_send: "Absenden",
    navigation_back: "Zurück",
    navigation_logout: "Ausloggen",
    navigation_menu: "Menü",
    navigation_close: "Schließen",
    navigation_cancel: "Abbrechen",
    navigation_save: "Speichern",
    legal_consent: "Zustimmen",
    legal_signature: "Unterschrift",
    legal_part_1: "Ich akzeptiere die ",
    legal_part_2: "Nutzungsbedingungen",
    legal_part_3: " und habe die ",
    legal_part_4: "Datenschutzerklärung",
    legal_part_5: " zur Kenntnis genommen.",
    legal_decline: "Ablehnen",
    legal_pictures: "* Bilder sind in der Regel urheberrechtlich geschützt.",
    legal_not_accepted:
        "Sie müssen den Nutzungsbedingungen zustimmen und die Datenschutzerklärung zur Kenntnis nehmen, um diese Aktion durchfüren zu können!",
    feature_flag_disabled_info: "Dieses Feature steht zum aktuellen Zeitpunkt leider nicht zur Verfügung.",
    toast_error: "Fehler!",
    toast_understood: "Verstanden!",
    toast_alright: "Alles klar!",
    toast_questionnaire_title: "Profiler Befragung",
    toast_questionnaire_bodyheight_cant_continue_info:
        "Ohne die Angabe der Körpergröße kann kein sinnvolles Ergebnis berechnet werden!",
    toast_questionnaire_gripsize_cant_continue_info: "Bitte geben Sie Werte für die Messungen der Hand an.",
    toast_questionnaire_bodymeasurement_cant_continue_info:
        "Bitte geben Sie die Körpermaße an oder führen Sie eine automatische Bilderkennung durch.",
    toast_questionnaire_personal_information_cant_continue_info:
        "Die angegebene E-Mail ist keine valide E-Mail Adresse. Bitte geben Sie eine valide oder keine E-Mail Adresse an, um fortzufahren.",
    toast_questionnaire_privacy_agreement_cant_continue_info:
        "Ohne Einwilligung zur Verarbeitung der Daten Ihrer gesundheitlichen Beschwerden können Ihnen keine passenden Fahrradprodukte empfohlen werden.",
    no_connection_warning_banner: "OFFLINE - NETZWERK ÜBERPRÜFEN",
    unsupported_device_warning_banner: "IPAD MODEL NICHT UNTERSTÜTZT - ES KANN ZU FEHLERN KOMMEN",
    upgrade_app_warning_banner: "BITTE UPDATEN SIE DIESE APP SOBALD WIE MÖGLICH!",
    upgrade_app_warning: "Diese App Version wird nicht mehr untersützt!\nBitte updaten Sie diese App über den AppStore.",
    upgrade_app_open_testflight_button: "AppStore öffnen",
    logout_confirmation_dialog_text: "Sind Sie sicher, dass Sie sich aus Ihrem Händler-Konto ausloggen möchten?",
    welcome_screen_title: "Willkommen!",
    menu_search_question_label: "Befragungen durchsuchen",
    menu_post_fitting_label: "Bike Fitting",
    menu_experimental_label: "Experimentelle Features",
    menu_question_label: "Befragung starten",
    menu_health_label: "Statusbericht",
    menu_settings_label: "Einstellungen",
    menu_statistics_label: "Statistik",
    menu_quickstart_label: "Schnell-Start",
    new_label: "NEU",
    pelvis: "Becken",
    hand: "Hand",
    foot: "Fuß",
    clothing: "Kleidung",
    bike: "Fahrrad",
    settings_health_rc_availability: "RC Verfügbarkeit",
    settings_health_connection_mobile: "Mobile Daten",
    settings_health_connection_wifi: "Wi-Fi",
    settings_health_connection_disconnected: "Keine Verbindung",
    settings_health_connection_error: "Keine Verbindung (Fehler)",
    settings_health_connection_status: "Verbindungsstatus",
    settings_health_cv_availability: "CV Verfügbarkeit",
    settings_health_hasura_availability: "Hasura Verfügbarkeit",
    settings_health_device_info: "Geräte Info",
    settings_health_build_info: "Build Info",
    settings_health_logs: "Logs",
    settings_health_licenses: "Lizenzen",
    settings_headline_settings: "Einstellungen",
    settings_headline_support: "Support",
    settings_headline_information: "Informationen",
    settings_select_manufacturers_button: "Hersteller auswählen",
    settings_select_product_groups_button: "Produktgruppen wählen",
    settings_select_manufacturers_headline: "Hersteller auswählen",
    settings_select_product_groups_headline: "Produktgruppen wählen",
    settings_select_product_groups_table_contact_point: "Ausgewählte Kontaktstelle",
    settings_select_product_groups_table_recommended_products: "Angezeigte Produktgruppen",
    settings_select_product_groups_table_pelvis: "Sättel, Hosen",
    settings_select_product_groups_table_hand: "Griffe / Lenkerband, Innerbarends, Handschuhe, Lenker, Vorbau",
    settings_select_product_groups_table_foot: "Pedale, Einlegesohlen",
    settings_select_product_groups_table_clothes: "Trikots, Hosen",
    settings_select_product_groups_table_bike: "Bike-Sizing, Fahrradmodelle",
    settings_select_product_groups_info:
        "Die Auswahl von Produktgruppen wirkt sich lediglich auf die nächste Befragung aus!",
    settings_select_manufacturers_select_all: "Alle auswählen",
    settings_select_manufacturers_deselect_all: "Alle abwählen",
    settings_new_name_field: "Neuer Name",
    settings_new_password_field: "Neues Passwort",
    settings_password_name_empty: "Sie haben keinen neuen Namen eingegeben. Überprüfen Sie bitte Ihre Eingabe.",
    settings_password_password_empty: "Sie haben kein Passwort eingegeben. Überprüfen Sie bitte Ihre Eingabe.",
    settings_password_password_not_repeated:
        "Sie haben sich beim Wiederholen des Passworts wohl vertippt. Überprüfen Sie bitte Ihre Eingabe.",
    settings_password_request_timeout:
        "Zeitüberschreitung bei der Verbindung. Das Passwort kann aktuell nicht neu gesetzt werden, bitte versuchen Sie es später erneut!",
    settings_password_request_error:
        "Aufgrund eines Serverfehlers kann das Passwort aktuell nicht zurückgesetzt werden. Bitte versuchen Sie es später erneut!",
    settings_name_request_success_part1: "Sie haben Ihren Namen erfolgreich geändert von {0} zu {1}",
    settings_name_request_success: "Sie haben Ihren Namen erfolgreich geändert!",
    settings_feedback_button: "Feedback",
    settings_contact_button: "Kontakt",
    settings_status_button: "Status",
    settings_imprint_button: "Impressum",
    settings_privacy_button: "Datenschutz",
    settings_usage_agreement_button: "Nutzungsbedingungen",
    settings_contact_text:
        "Bei Fragen und Anregungen zur SQlab Profiler App, kontaktieren Sie Ihren Kundenbetreuer {0} oder schreiben Sie eine E-Mail an profiler@sq-lab.com!",
    settings_contact_note: "Hinweis",
    settings_contact_text_1:
        "Bei Fragen, Problemen und Anregungen steht Ihnen SQlab zur Verfügung. Kontaktieren Sie uns dafür per E-Mail unter ",
    settings_contact_text_2: " oder Ihren Kundenbetreuer ",
    settings_contact_text_3: "Geben Sie bitte bei allen Anfragen Ihre Account-Nummer an:",
    settings_changelanguage_headline: "Sprache / Einheit ändern",
    settings_changelanguage_failure: "Die Sprache konnte nicht geändert werden. Bitte versuche es erneut!",
    settings_deactivate_label: "Deaktivieren",
    settings_deactivate_user_text: "Diesen Benutzer deaktivieren",
    settings_user_is_deactivated: "Benutzer wurde deaktiviert",
    settings_deactivate_user_confirmation_text:
        "Sind Sie sicher, dass Sie diesen User deaktivieren möchten? Der User wird dann permanent nicht mehr verfügbar sein.",
    questionnaire_recommended_products_button: "Produkte",
    questionnaire_product_sold_button: "Verkauft",
    questionnaire_sell_product_button: "Verkaufen",
    questionnaire_sell_product_confirmation_info: "Bist du sicher, dass du dieses Produkt an einen Kunden verkauft hast?",
    questionnaire_sell_product_confirmation_confirm: "Ja, verkauft!",
    questionnaire_product_no_description: "Keine Beschreibung vorhanden.",
    questionnaire_product_description_headline: "Beschreibung: ",
    questionnaire_product_size_headline: "Größe: ",
    questionnaire_product_available_soon: "Artikel bald verfügbar",
    questionnaire_product_open_online_shop: "Onlineshop öffnen",
    questionnaire_no_products_found: "Es wurden leider keine Produkte gefunden.",
    questionnaire_email_success: "E-Mail erfolgreich versendet!",
    questionnaire_email_failure: "E-Mail Versand fehlgeschlagen!",
    questionnaire_data_confirmation:
        "Sind Sie sicher, dass die Daten dieser Befragung unwiederruflich gelöscht werden sollen?",
    questionnaire_no_product_groups:
        "Es wurden keine Produktgruppen ausgewählt. Wählen Sie Produktgruppen in den Einstellungen aus.",
    questionnaire_data_delete_success: "Daten erfolgreich gelöscht!️",
    questionnaire_data_delete_failure: "Daten konnten nicht gelöscht werden!",
    questionnaire_summary_no_answer_label: "Keine Angabe",
    questionnaire_summary_pictures_headline: "Digitale Vermessung",
    camera_help_label: "Hilfe",
    camera_start_process_button: "Bilderkennung starten",
    camera_repeat_button: "Erneut aufnehmen",
    profile_overview_info_box_content:
        "Hinweis: Die Nutzer Ihres Händler-Accounts sind für die Profiler App und die Schulungsplattform iQlab identisch und können auf beiden Plattformen verwendet werden.",
    profile_forgot_password_email_not_valid: "Bitte geben Sie eine valide EMail Adresse an!",
    answeroverview_detail_loading_text: "Kundenbefragung wird geladen...",
    answeroverview_products_loading_text: "Artikelempfehlungen werden geladen...",
    answeroverview_overview_loading_text: "Befragungsübersicht wird geladen...",
    answeroverview_filter_loading_text: "Filter wird angewendet...",
    answeroverview_next_page_loading_text: "Nächste Seite wird geladen...",
    answeroverview_previous_page_loading_text: "Vorherige Seite wird geladen...",
    retry_button: "Erneut versuchen",
    email_language_selection_question: "In welcher Sprache sollen E-Mails verschickt werden?",
    edit_answers_button: "Antworten bearbeiten",
    edit_answers_after_done_button: "Antworten",
    question_help_button: "Hilfe",
    question_cancel_button: "Abbrechen",
    question_cancel_dialog_text: "Soll die Befragung abgebrochen werden?",
    question_cancel_dialog_continue_button: "Abbrechen",
    question_cancel_dialog_confirm_button: "Weitermachen",
    question_submitted_loading_text: "Passende Produkte werden berechnet...",
    question_starting_loading_text: "Die Befragung wird vorbereitet...",
    unknown_exception:
        "Leider ist bei dieser Aktion ein unbekannter Fehler aufgetreten.\nFalls ein App-Update verfügbar ist, installieren Sie dieses bitte.\nAnsonsten probieren Sie bitte, die Aktion erneut durchzuführen.",
    timeout_exception:
        "Zeitüberschreitung bei der Verbindung.\nÜberprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
    no_connection_exception: "Diese Aktion kann nicht durchgeführt werden, da keine Verbindung zum Internet besteht.",
    questionnaire_no_result_exception:
        "Für diese Eingaben konnten leider keine Produktempfehlungen ermittelt werden.\nVerwenden Sie spezifischere Eingaben und versuchen Sie es erneut.",
    questionnaire_prepare_server_error_exception:
        "Die Befragung kann derzeit aufgrund von Serverproblemen nicht gestartet werden. Bitte versuchen Sie es später erneut!",
    questionnaire_finish_server_error_exception:
        "Die Befragungsdaten konnten nicht verarbeitet werden. Bitte versuchen Sie es später erneut.",
    questionnaire_summary_headline: "Antwort Übersicht",
    questionnaire_finish_headline: "Empfohlene Produkte",
    questionnaire_sold_product_stamp: "VERKAUFT",
    post_buy_fitting_loading_text: "Post Buy Fitting Analyse starten...",
    markdown_document_no_load:
        "Das angeforderte Dokument konnte nicht abgerufen werden.\nBitte überprüfen Sie die Internetverbindung.",
    to_home_button_text: "Menü",
    to_home_cancel_text: "Weitermachen",
    to_home_info_text: "Möchten Sie wirklich zum Hauptmenü zurückkehren?",
    to_home_confirmation_text: "Hauptmenü",
    no_data_available: "Nicht angegeben",
    no_problem_available: "Kein Problem angegeben",
    multiple_choice_title: "Mehrfachauswahl möglich",
    sex_question_question_text: "Geschlecht",
    female: "Frau",
    male: "Mann",
    divers: "Divers",
    ebike_question_question_text: "E-Bike",
    ebike_yes: "Ja",
    ebike_no: "Nein",
    driving_duration_question_question_text: "Fahrdauer pro Tag",
    lessThan45min: "weniger als 45 Minuten",
    between45minAnd2h: "45 Minuten bis 2 Stunden",
    moreThan2h: "mehr als 2 Stunden",
    bicycle_type_question_question_text: "Fahrradtyp",
    triathlon_bike: "Triathlon",
    racing_bike: "Rennrad",
    mtb_xc: "MTB Tech & Trail",
    mtb_all_mountain: "MTB Gravity & E-Performance",
    trekking_cross: "MTB Tour & Travel",
    trekking_comfort: "Trekking",
    city_comfort: "City",
    gravel: "Gravel",
    seat_position_question_question_text: "Sitzposition",
    triathlon: "Triathlon",
    sportive: "Sportlich",
    moderate: "Moderat",
    slightly_bent: "Leicht gebeugt",
    upright: "Aufrecht",
    leg_axis_question_question_text: "Beinachse",
    o_legs: "O-Beine",
    straight_legs: "Gerade Beine",
    x_legs: "X-Beine",
    foot_position_question_question_text: "Fußstellung",
    v: "V-förmig",
    straight: "Gerade",
    gripping_distance_question_question_text: "Griffgröße",
    S: "S",
    M: "M",
    L: "L",
    XL: "XL",
    gripping_distance_question_gripWidth_label: "Griffweite",
    gripping_distance_question_handWidth_label: "Handbreite",
    driving_frequency_question_question_text: "Fahrhäufigkeit",
    several_times_a_week: "Mehrmals die Woche",
    one_time_a_week: "Einmal die Woche",
    every_two_weeks: "Alle 2 Wochen",
    every_four_weeks: "Alle 4 Wochen",
    foot_type_question_question_text: "Fußtyp",
    arches_foot: "Hohlfuß",
    normal: "Normalfuß",
    arched_foot: "Senkfuß",
    flat_foot: "Plattfuß",
    foot_problem_question_question_text: "Probleme Fuß",
    numb_toes: "Taubheitsgefühle Zehen",
    knee_discomfort: "Knie Beschwerden",
    hand_problem_question_question_text: "Probleme Hand",
    numb_thumb_index_and_middle_finger: "Tauber Daumen, Zeige- und Mittelfinger",
    numb_little_and_ring_finger: "Tauber kleiner und Ringfinger",
    wrist: "Handgelenk",
    upper_back: "Oberer Rücken (Schulter/Nacken/Halswirbel)",
    seat_bone_distance_question_question_text: "Sitzknochenabstand",
    length_input_widget_value_toosmall_text: "Der eingegebene Wert ist zu klein.",
    length_input_widget_value_toobig_text: "Der eingegebene Wert ist zu groß.",
    shoe_size_question_question_text: "Schuhgröße (EU)",
    weight_question_question_text: "Körpergewicht",
    weight_question_sub60_label: "Bis 60 kg",
    weight_question_sub70_label: "Bis 70 kg",
    weight_question_sub80_label: "Bis 80 kg",
    weight_question_sub90_label: "Bis 90 kg",
    weight_question_sub100_label: "Bis 100 kg",
    weight_question_sub110_label: "Bis 110 kg",
    weight_question_sub120_label: "Bis 120 kg",
    weight_question_plus120_label: "Über 120 kg",
    weight_question_sub60_label_imperial: "Bis 132 Pfund",
    weight_question_sub70_label_imperial: "Bis 153 Pfund",
    weight_question_sub80_label_imperial: "Bis 176 Pfund",
    weight_question_sub90_label_imperial: "Bis 198 Pfund",
    weight_question_sub100_label_imperial: "Bis 220 Pfund",
    weight_question_sub110_label_imperial: "Bis 242 Pfund",
    weight_question_sub120_label_imperial: "Bis 264 Pfund",
    weight_question_plus120_label_imperial: "Über 264 Pfund",
    weight_question_custom_label: "Bis {0} kg",
    pelvic_problems_question_question_text: "Probleme Becken",
    numbness: "Taubheitsgefühle",
    sitbones: "Sitzknochen",
    coccyx: "Steißbein",
    pubic_bone: "Schambein",
    prostate: "Prostata",
    lower_back: "Unterer Rücken",
    pelvic_malalignment: "Beckenfehlstellung",
    heat_sweat: "Hitze/Schweiß",
    personal_question_question_text: "Kontakt",
    personal_question_question_optional_disclaimer: "* Diese Angaben sind optional",
    privacy_agreement_question_text: "Datenverarbeitung",
    ergonomics_profile_label: "Ergonomie Profil",
    summary_page_other_values_label_ipad: "Kontaktdaten & Persönliche Präferenzen",
    summary_page_other_values_label_iphone: "Kontaktdaten &\nPersönliche Präferenzen",
    summary_page_other_from_connectingword: "vom",
    body_measurements_question_question_text: "Körpermaße",
    body_measurements_question_question_stepLength: "Schrittlänge",
    body_measurements_question_question_shoulderWidth: "Schulterbreite",
    body_measurements_question_question_armLength: "Armlänge",
    body_measurements_help_text: `
  Achten Sie bei der Durchführung der Körpervermessung auf folgende Punkte:
    • Es befindet sich nur eine Person im Bildausschnitt
    • Der Raum ist natürlich beleuchtet
    • Der Kunde wird frontal aufgenommen
    • Das Gerät wird bei der Aufnahme still und frontal zum Kunden gehalten
    • Der Kunde ist vollständig im Bildausschnitt zu sehen
    • Der Kunde trägt bei der Vermessung möglichst keine Schuhe
  `,
    body_measurements_help_text_title_iPad: "Informationen zur automatischen Körpervermessung",
    body_measurements_help_text_title_iPhone: "Info Körpervermessung",
    body_height_question_question_text: "Körpergröße",
    bike_fitting_analysis_title: "Bike Fitting Analyse",
    bike_fitting_analysis_success_title: "Bike Fitting Analyse Ergebnisse",
    bike_fitting_analysis_failure: "Analyse fehlgeschlagen",
    bike_fitting_start_button: "Starten",
    bike_fitting_start_guide_title: "Anleitung",
    bike_fitting_start_guide:
        "1. Testperson auf stehendem Fahrrad platzieren.\n2. Testperson muss in die Pedale treten, bevor die Aufnahme gestartet wird.\n3. Aufnahme starten. Nach der Aufnahme auf die Fertigstellung der Analyse warten! Der Prozess kann bis zu einer Minute dauern.",
    bike_fitting_restart_button: "Neustart",
    bike_fitting_result_max_knee_angle_title: "Maximaler Kniewinkel",
    bike_fitting_result_knee_lot_title: "Knie Lot",
    bike_fitting_result_adjustments_title: "Erforderliche Anpassungen am Fahrrad",
    bike_fitting_result_adjustments_content:
        "In der aktuellen Version der App können noch keine Aussagen über mögliche Anpassungen am Fahrrad getroffen werden. Wenn Sie uns Feedback zu den Messergebnissen senden wollen, benutzen Sie bitte das Feedback Formular in den Einstellungen der App.",
    bike_fitting_result_show_graph_button: "Verlaufs-Graph ansehen",
    bike_fitting_loading_video_process: "Video wird verarbeitet...",
    bike_fitting_knee_angle: "Kniewinkel",
    bike_fitting_min_knee_angle: "Min. Kniewinkel",
    bike_fitting_max_knee_angle: "Max. Kniewinkel",
    bike_fitting_knee_angle_not_measured: "Nicht gemessen",
    bike_fitting_change_view_direction: "Richtung ändern",
    health_page_title: "Statusbericht",
    try_vendor_login_loading_text: "Versuche den Händler-Account zu authentifizieren...",
    try_profile_login_loading_text: "Versuche den Nutzer zu authentifizieren...",
    vendor_login_loading_text: "Authentifiziere den Händler-Account...",
    profile_login_loading_text: "Authentifiziere den Nutzer...",
    create_profile_loading_text: "Lege den neuen Nutzer an...",
    login_button: "Login",
    create_profile_button: "Erstellen",
    create_profile_information:
        "Mit einem Nutzer führen sie Kundenbefragungen durch, sehen historische Befragungen ein und sehen Ihre Befragungsstatistiken und Provision ein! Der angelegte Nutzer kann auch auf iQlab verwendet werden.",
    create_profile_first_name_field: "Vorname",
    create_profile_last_name_field: "Nachname",
    create_profile_first_name_empty:
        "Der angegebene Vorname darf nicht leer sein. Bitte geben Sie einen Namen für den Nutzer an.\n\n",
    create_profile_last_name_empty:
        "Der angegebene Nachname darf nicht leer sein. Bitte geben Sie einen Namen für den Nutzer an.\n\n",
    create_profile_email_invalid:
        "Die angegebene E-Mail ist keine valide E-Mail Adresse. Bitte geben Sie eine valide E-Mail Adresse für den Nutzer an.\n\n",
    create_profile_password_empty:
        "Das angegebene Passwort darf nicht leer sein. Bitte geben Sie ein Passwort für den Nutzer an.\n\n",
    create_profile_password_repeat_no_match:
        "Das Passwort stimmt nicht mit dem wiederholten Passwort überein. Beide Einträge müssen identisch sein.\n\n",
    login_info_toast_title: "SQlab Login",
    login_password_reset_iqlab_hint:
        "Wenn Sie das Passwort zurücksetzen, wird auch das Passwort Ihres iQlab Accounts zurück gesetzt. Möchten Sie fortfahren?",
    profile_forgot_password_info:
        "Um ihr Passwort zurückzusetzen, geben Sie hier bitte die E-Mail von Ihrem Account an.\nWeitere Informationen erhalten Sie in der E-Mail, die wir Ihnen zuschicken.",
    vendor_login_info_text: "Loggen Sie sich hier mit Ihrem B2B Login ein, um mit dem SQlab Profiler zu starten:",
    vendor_login_b2c_notice:
        "Diese App dient als Werkzeug für den Fahrrad Fachhandel. Die Anwendung durch Privat-Personen (B2C Kunden) ist nicht vorgesehen.",
    vendor_login_vendor_id_label: "Händler-ID",
    vendor_login_vendor_password_forgot_info:
        "Bitte melden Sie sich telefonisch bei SQlab, um ihr Passwort zurückzusetzen!",
    profile_login_iqlab_info_text:
        "Dieser Nutzer ist nicht durch ein Passwort geschützt. Seit Version 0.10.0 werden diese Nutzer nicht mehr unterstützt. Bitte verwenden Sie die 'Passwort vergessen?' Funktion, um ein Passwort für diesen Nutzer anzufordern.\n\n>>Danach müssen Sie die App neu starten.<<",
    login_no_connection: "Sie können sich nicht einloggen, da keine Verbindung zum Internet besteht.",
    profile_creation_timeout: "Der Nutzer konnte nicht erstellt werden. Bitte versuchen Sie es später erneut!",
    profile_creation_email_exists: "Ein Nutzer mit dieser E-Mail Adresse existiert bereits.",
    profile_creation_no_connection: "Sie können keinen neuen Nutzer erstellen, da keine Verbindung zum Internet besteht.",
    profile_creation_error: "Bei dem Anlegen des Nutzers ist ein Fehler aufgetreten. Versuchen Sie es später erneut.",
    stats_tab: "Statistik",
    ranking_tab: "Ranking",
    provision_tab: "Provision",
    stats_headline: "Persönliche Statistik",
    stats_number_questionnaires: "Anzahl deiner Befragungen",
    stats_number_sold_products: "Deine verkauften Produkte",
    stats_duration_median: "Durchschnittslänge deiner Befragungen",
    stats_duration_minutes: "min",
    stats_duration_total: "Gesamtlänge deiner Befragungen",
    stats_provision_disclaimer: "Wir befinden uns in der Beta-Phase.\nEs wird keine Provision ausgezahlt",
    stats_provision_boost: "Bis zum {0} ist Ihre Provision pro Befragung auf {1}{2} erhöht.",
    stats_provision_boost_title: "Provisions-Boost",
    stats_provision_questionnaire_provision_legend: "Beratungsprovision",
    stats_provision_online_provision_legend: "Umsatzprovision",
    stats_provision_personal_contribution_legend: "Eigener Beitrag",
    stats_provision_team_contribution_legend: "Team Beitrag",
    stats_provision_personal_contribution_headline: "Eigener Beitrag",
    stats_provision_team_headline: "Teamkasse",
    stats_commission_info:
        "Beratungsprovision wird nur ausgezahlt, wenn alle Produktgruppen abgefragt werden und der Kunde die Ergebnisse gedruckt oder per E-Mail bekommt.",
    stats_provision_net: "netto: {0}",
    stats_provision_no_individual_contribution: "Sie haben noch keine Provision.",
    stats_provision_no_team_contribution:
        "Ihr Team hat noch keine Provision erarbeitet. Provisionen werden für durchgeführte Befragungen und Käufe im SQlab Online Shop gezahlt.",
    stats_provision_error:
        "Die Provisions-Daten konnten nicht geladen werden. Bitte versuchen Sie es in einem Moment erneut !",
    ranking_no_profiles_error: "Es gibt leider keine Nutzer.",
    ranking_score: "Score:",
    historic_table_id: "ID",
    historic_table_time: "Uhrzeit",
    historic_table_customer: "Kunde",
    historic_table_profile: "Nutzer",
    historic_search_result_text: "{0} Befragungen zu dieser Suche gefunden",
    historic_no_questionnaires_available:
        "Mit diesem Nutzer wurden noch keine Befragungen durchgeführt. Sobald Befragungen durchgeführt wurden, werden diese hier angezeigt.",
    historic_no_questionnaires_found: "Für diese Suchanfrage konnten keine Befragungen gefunden werden.",
    historic_no_connection_overview_error:
        "Befragungsdaten können nicht abgerufen werden, da aktuell keine Verbindung zum Internet besteht.",
    historic_timeout_overview_error:
        "Zeitüberschreitung bei der Verbindung.\nBefragungsdaten können derzeit nicht abgerufen werden. Versuchen Sie es später erneut.",
    historic_server_overview_error:
        "Befragungsdaten können derzeit aufgrund eines Serverfehlers nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
    historic_no_connection_detail_error:
        "Detailierte Daten zu dieser Befragung können nicht abgerufen werden, da aktuell keine Verbindung zum Internet besteht.",
    historic_timeout_detail_error:
        "Zeitüberschreitung bei der Verbindung.\nDetailierte Daten zu dieser Befragung können derzeit nicht abgerufen werden. Versuchen Sie es später erneut.",
    historic_server_detail_error:
        "Detailierte Daten zu dieser Befragung können derzeit aufgrund eines Serverfehlers nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
    historic_no_connection_products_error:
        "Artikel Empfehlungen zu dieser Befragung können nicht abgerufen werden, da aktuell keine Verbindung zum Internet besteht.",
    historic_timeout_products_error:
        "Zeitüberschreitung bei der Verbindung.\nArtikel Empfehlungen zu dieser Befragung können derzeit nicht abgerufen werden. Versuchen Sie es später erneut.",
    historic_server_products_error:
        "Artikel Empfehlungen zu dieser Befragung können derzeit aufgrund eines Serverfehlers nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
    historic_selected_product_groups_label: "Produktgruppen",
    product_type_saddle: "Sattel",
    product_type_handle: "Griffe",
    product_type_handlebar_tape: "Lenkerband",
    product_type_innerbarends: "Innerbarends",
    product_type_handlebars: "Lenker",
    product_type_stem: "Vorbau",
    product_type_pedal: "Pedale",
    product_type_insole: "Einlegesohlen",
    product_type_jersey: "Trikots",
    product_type_shorts: "Kurze Hosen",
    product_type_gloves: "Handschuhe",
    product_type_shoes: "Schuhe",
    product_type_knee_pads: "Knieschoner",
    product_type_elbow_pads: "Ellenbogenschoner",
    product_type_helmet: "Helme",
    product_type_pants: "Lange Hosen",
    product_type_bike: "Fahrrad",
    product_type_socks: "Socken",
    product_type_unknown: "Unbekannt",
    question_label_bicycle_type: "Fahrradtyp",
    question_label_e_bike: "E-Bike",
    question_label_sitbone_distance: "Sitzknochenabstand",
    question_label_sitting_position: "Sitzposition",
    question_label_saddle_width: "Sattelbreite",
    question_label_riding_frequency: "Fahrhäufigkeit",
    question_label_riding_time: "Fahrdauer",
    question_label_bodyweight: "Körpergewicht",
    question_label_gender: "Geschlecht",
    question_label_contact_point_pelvis: "Probleme Becken",
    question_label_grip_size: "Griffgröße",
    question_label_contact_point_hand: "Probleme Hand",
    question_label_foot_type: "Fußtyp",
    question_label_leg_axis: "Beinachse",
    question_label_foot_position: "Fußstellung",
    question_label_shoe_size: "Schuhgröße",
    question_label_contact_point_foot: "Probleme Fuß",
    question_label_shoulder_width_handlebar: "Schulterbreite Lenker",
    question_label_bodyheight: "Körpergröße",
    question_label_bodyheight_bicycle: "Körpergröße Fahrrad",
    question_label_clothing_size: "Kleidungsgröße",
    question_label_pedal_size: "Pedalgröße",
    question_label_insoles_size: "Größe Einlegesohlen",
    question_label_gloves_size: "Handschuhgröße",
    question_label_unknown: "Unbekannt",
    pdf_value_missing: "Fehlt",
    pdf_title: "Artikel Empfehlungen",
    pdf_subtitle: "SQlab Profiler",
    pdf_description: "Empfehlungen {0} am {1} Uhr",
    pdf_for_aux: "für",
    pdf_expert: "Berater/in",
    pdf_article_column_title: "Art.",
    pdf_type_column_title: "Typ",
    pdf_brand_column_title: "Marke",
    pdf_product_column_title: "Produkt",
    pdf_fit_column_title: "Fit",
    pdf_bold_note: "Hinweis: Der am besten passende Artikel je Kategorie ist fett gedruckt",
    hasura_user_repository_createProfile_timeout_exception:
        "Gerade kann kein Nutzer erstellt werden.\nBitte versuchen Sie es später erneut!",
    hasura_user_repository_loginWithToken_timeout_exception:
        "Der Login ist aktuell nicht verfügbar.\nBitte versuchen Sie es später erneut.",
    hasura_user_repository_requestOneTimePassword_timeout_exception:
        "Das Passwort konnte nicht zurückgesetzt werden.\nBitte versuchen Sie es später erneut!",
    hasura_user_repository_resetPassword_timeout_exception:
        "Das Passwort konnte nicht geändert werden.\nBitte versuchen Sie es später erneut!",
    hasura_ranking_repository_getRankingData_timeout_exception:
        "Ranking konnte nicht abgerufen werden. Zeitüberschreitung bei der Verbindung.",
    hasura_settings_repository_getManufacturers_timeout_exception:
        "Die Herstellerliste kann nicht abgefragt werden.\nBitte versuchen Sie es später erneut.",
    hasura_settings_repository_updateExcludedBikeManufacturers_timeout_exception:
        "Die Herstellerliste kann nicht aktualisiert werden.\nBitte versuchen Sie es später erneut.",
    hasura_statistics_repository_getStatisticsNumbers_timeout_exception:
        "Die Anzahl Befragungen kann nicht abgerufen werden. Zeitüberschreitung bei der Verbindung.",
    hasura_statistics_repository_updateCommission_timeout_exception:
        "Die Provision kann nicht aktualisiert werden.\nBitte versuchen Sie es später erneut.",
    numpad_errorhint_too_small: "Der eingegebene Wert ist zu klein.",
    numpad_errorhint_too_big: "Der eingegebene Wert ist zu groß.",
    sitbone_distance_question_goToNextPageNotAllowedText:
        "Der eingegebene Wert ist entweder zu klein oder zu groß. Bitte korrigieren Sie Ihre Eingabe.",
    done_label: "Fertig",
    save_label: "Speichern",
    none_label: "Keine",
    signed_label: "Unterschrieben",
    not_signed_label: "Nicht Unterschreiben",
    questionnaire_summary_no_email: "Keine E-Mail",
    questionnaire_summary_no_name: "Kein Name",
    ebike_answer_yes: "Ja E-Bike",
    ebike_answer_no: "Kein E-Bike",
    questionnaire_summary_edit_question: "Bearbeiten",
    recommended_products_bike_size: "Fahrradgröße",
    recommended_products_stack: "Stack",
    recommended_products_reach: "Reach",
    recommended_products_info_label_stack_and_reach: "Stack und Reach",
    recommended_products_str_quotient: "StR Quotient",
    recommended_products_category_bike_profile: "Fahrrad Profil",
    cv_error_code_100: "Papier ist sehr klein",
    cv_error_code_101: "Sehr wenige Punkte erkannt",
    cv_error_code_103: "Unrealistische Distanz erkannt",
    cv_error_code_104: "Bild ist von einem ungünstigen Winkel aufgenommen",
    cv_error_code_105: "Schlechte Lichtverhältnisse",
    cv_error_code_106: "Hand nicht gut gestreckt",
    cv_error_code_107: "Körper sehr klein",
    cv_error_code_400: "Kein Sitzknochenpapier erkannt",
    cv_error_code_401: "Nicht genügend Punkte erkannt",
    cv_error_code_402: "Nicht genügend Punkte für anständiges Clustering erkannt",
    cv_error_code_403: "Nicht genügend Punkte pro Cluster",
    cv_error_code_404: "Bildauflösung ist zu niedrig",
    cv_error_code_405: "Nicht unterstütztes Bildformat",
    cv_error_code_406: "Bildauflösung ist zu hoch",
    cv_error_code_407: "Zu viele Hände erkannt",
    cv_error_code_408: "Keine Hand erkannt",
    cv_error_code_409: "Daumen nicht gestreckt",
    cv_error_code_410: "Kein Referenzobjekt erkannt",
    cv_error_code_411: "Hand nicht gut genug erkannt",
    cv_error_code_412: "Keinen Körper erkannt",
    cv_error_code_413: "Körper nicht frontal aufgenommen",
    cv_error_code_414: "Zu viele Punkte erkannt",
    cv_error_code_419: "Hand ist nicht parallel zum Gerät",
    cv_error_code_420: "Finger nicht genug gestreckt",
    cv_error_code_500: "Unerwarteter Fehler",
    cv_error_code_600: "Validierungsfehler",
    cv_error_code_700: "Verbindungsproblem",
    cv_error_code_800: "Unbekannter Fehler",
    cv_error_code_unknown: "Unbekannter Fehler",
    cv_suggestion_code_100: "Stellen Sie sicher, dass das Papier den Großteil des Bildes ausmacht.",
    cv_suggestion_code_101:
        "Stellen Sie bessere Lichtverhältnisse her und sorgen Sie dafür,\ndass sich genügend Löcher im Papier abgebildet haben.",
    cv_suggestion_code_103: "Stellen Sie bessere Lichtverhältnisse her und vermeiden Sie Reflektionen auf dem Blatt",
    cv_suggestion_code_104: "Nehmen Sie das Bild direkt von oben frontal auf.",
    cv_suggestion_code_105: "Stellen Sie bessere Lichtverhältnisse her.",
    cv_suggestion_code_106: "Strecken Sie den Daumen weiter von der Hand weg.",
    cv_suggestion_code_107: "Der Körper sollte einen größeren Teil des Bildes ausmachen",
    cv_suggestion_code_400:
        "Das Papier sollte den Großteil des Bildes ausmachen. Achten Sie darauf, die weiße Rückseite des Sitzknochenpapieres auf einer ebenen und nicht leuchtenden Unterlage zu fotografieren.",
    cv_suggestion_code_401:
        "Achten Sie darauf, die weiße Rückseite des Sitzknochenpapieres auf einer ebenen und nicht leuchtenden Unterlage zu fotografieren.",
    cv_suggestion_code_402:
        "Achten Sie darauf, die weiße Rückseite des Sitzknochenpapieres auf einer ebenen und nicht leuchtenden Unterlage zu fotografieren.",
    cv_suggestion_code_403:
        "Achten Sie darauf, die weiße Rückseite des Sitzknochenpapieres auf einer ebenen und nicht leuchtenden Unterlage zu fotografieren.",
    cv_suggestion_code_404: "Verwenden Sie eine höhere Bildauflösung.",
    cv_suggestion_code_405: "Verwenden Sie nur JPG oder PNG Dateien für die Bilderkennung.",
    cv_suggestion_code_406: "Verwenden Sie eine kleinere Bildauflösung.",
    cv_suggestion_code_407: "Stellen Sie sicher, dass nur eine Hand auf dem Bild ist.",
    cv_suggestion_code_408: "Stellen Sie sicher, dass das Bild eine Hand zeigt.",
    cv_suggestion_code_409: "Strecken Sie den Daumen mehr durch.",
    cv_suggestion_code_410:
        "Achten Sie darauf, dass der Referenzsticker vollständig im Bild zu sehen ist, nicht überdeckt wird und auf einem ebenen Untergrund liegt.",
    cv_suggestion_code_411: "Stellen Sie sicher, dass die Hand gut sichtbar ist.",
    cv_suggestion_code_412: "Stellen Sie sicher, dass der ganze Körper gut sichtbar ist.",
    cv_suggestion_code_413: "Stellen Sie sicher, dass der Körper frontal aufgenommen wird.",
    cv_suggestion_code_414:
        "Stellen Sie sicher, dass sich zwei Punktecluster auf dem verwendeten Sitzknochenpapier befinden.",
    cv_suggestion_code_419: "Stellen Sie sicher, dass die Hand parallel zum Gerät zeigt.",
    cv_suggestion_code_420: "Stellen Sie sicher, dass die Finger ausgestreckt werden.",
    cv_suggestion_code_500: "Unbekannter Fehler",
    cv_suggestion_code_600: "Bitte die Debug Logs konsultieren",
    cv_suggestion_code_800: "Stellen Sie sicher, dass die neueste Version der App auf Ihrem Gerät installiert ist.",
    cv_suggestion_code_unknown: "Stellen Sie sicher, dass die neueste Version der App auf Ihrem Gerät installiert ist.",
    camera_device_frontal_info: "Bitte halten Sie das Gerät frontal auf den Kunden.",
    camera_device_horizontal_info: "Bitte halten Sie das Gerät waagerecht.",
    camera_sitbones_title: "Sitzknochenvermessung",
    camera_sitbones_orientation_help: "Bitte richten Sie das Gerät auf das verwendete\nSitzknochenpapier!",
    camera_sitbones_info:
        "Richten Sie die Geräte-Kamera auf das verwendete\nSitzknochenpapier und drücken Sie den Auslöser!",
    camera_handmark_title: "Handvermessung",
    camera_handmark_orientation_help: "Bitte richten Sie das Gerät auf die Hand des Kunden!",
    camera_handmark_info:
        "Richten Sie die Geräte-Kamera auf die Hand des Kunden.\nDer Referenzsticker muss vollständig im Bild sein!",
    camera_crotch_title: "Körpervermessung",
    camera_crotch_orientation_help: "Bitte richten Sie das Gerät auf den Kunden und\nhalten es dabei senkrecht!",
    camera_crotch_info:
        "Richten Sie die Geräte-Kamera auf den Kunden und drücken\nSie den Auslöser! Der Kunde sollte keine Schuhe\ntragen und Arme und Beine etwas abspreizen.",
    camera_kneeangle_title: "Kniewinkel",
    camera_kneeangle_orientation_help: "",
    camera_kneeangle_info: "",
    scan3d_repeat_button: "Bild erneut aufnehmen",
    experimental_title: "Experimentelle Features",
    experimental_title_bikefitting: "Bike Fitting",
    experimental_title_hand_scan3d: "3D Hand Scan",
    experimental_title_hand_scan: "2D Hand Scan",
    experimental_title_foot_scan: "Fuß Scan",
    experimental_title_crotch_scan: "Körpervermessung",
    experimental_title_sitbones_scan: "Sitzknochen Scan",
    error_message_title: "Ein Fehler ist aufgetreten",
    error_message_content: "Probieren Sie es erneut oder wenden Sie sich an einen Mitarbeiter.",
    answers_sending_error_title: "Antworten konnten nicht abgeschickt werden",
    answers_sending_error_content: "Probieren Sie es erneut oder wenden Sie sich an einen Mitarbeiter.",
    non_optional_warning_title: "Bitte wählen Sie eine Antwortmöglichkeit.",
    bike_type: "Fahrradtyp",
    bike_title: "Fahrrad",
    bike_profile: "Fahrradprofil",
    frame_size: "Rahmengröße",
    ride_height: "Sitzhöhe",
    sitBones: "Sitzknochen",
    bodyHeight: "Körpergröße",
    bodyMeasurements: "Körpermaße",
    gripSize: "Griffgröße",
    shoeSize: "Schuhgröße"
};
