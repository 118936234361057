import * as React from "react";
import {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {Outlet, useSearchParams} from "react-router-dom";
import logo from "../assets/sqlab_logo.png";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

export default function AppBarLayout() {
  const { t } = useTranslation();

  const [backToMenuClicked, setBackToMenuClicked] = useState(false);
  const [searchParams] = useSearchParams();

  const routeToMenu = () => {
    setBackToMenuClicked(true);
  };

  const navigate = useNavigate();
  const returnToMenu = () => {
    navigate({ pathname: "/", search: searchParams.toString() });
    setBackToMenuClicked(false);
  };
  const stayOnPage = () => {
    setBackToMenuClicked(false);
  };

  return (
    <>
      <Box sx={{ height: "100vh" }} display="flex" flexDirection="column">
        <AppBar
          position="static"
          sx={{
            bgcolor: "background.paper",
            borderBottom: 3,
            borderBottomColor: "primary.main",
          }}
        >
          <Toolbar>
            <Box sx={{ height: "64px", py: 1, cursor: "pointer" }} onClick={routeToMenu}>
              <img src={logo} alt={"SQlab"} height="100%" />
            </Box>
          </Toolbar>
        </AppBar>
        <Outlet />
      </Box>
      <Dialog open={backToMenuClicked}>
        <DialogTitle style={{ textAlign: "center" }}>{t("to_home_info_text")}</DialogTitle>
        <DialogActions>
          <Button variant={"outlined"} onClick={stayOnPage} color={"primary"}>
            {t("question_cancel_dialog_confirm_button")}
          </Button>
          <Button
            variant={"outlined"}
            onClick={returnToMenu}
            style={{ float: "right", color: "red", borderColor: "red" }}
          >
            {t("to_home_confirmation_text")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
