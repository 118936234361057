import {Card, Typography} from "@mui/material";
import SuggestedItem from "./SuggestedItem";
import {useTranslation} from "react-i18next";
import {Recommendation} from "../../pages/Results";

export default function SuggestionGroup(props: { title: string; items: Recommendation[] }) {
  const { t } = useTranslation();

  return (
    <div style={{ margin: "1em" }}>
      <Typography
        variant="h4"
        // sx={{ width: "100%", borderRadius: 8 }}
        // style={{ color: "black", marginBottom: "0.5em" }}
      >
        {t(`product_type_${props.title}`)}
      </Typography>
      <Card>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.items.map((item) => {
            return (
              <div style={{ width: "33%" }}>
                <SuggestedItem item={item}></SuggestedItem>
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
}
