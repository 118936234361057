import {Box, Button, Container, TextField} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleLogin = async (e: any) => {
    e.preventDefault(); // page shall not re-render

    const authParams = `${email}:${password}`;
    // TODO use not deprecated approach to convert string to base64
    setSearchParams({ ...searchParams, auth: btoa(authParams) });
  };

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <form onSubmit={handleLogin}>
          <h1>{t("login_button")}</h1>
          <Box mt={2}>
            <TextField
              label={t("app_email")}
              variant={"outlined"}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              autoFocus
            />
          </Box>
          <Box mt={2}>
            <TextField
              label={t("app_password_label")}
              variant={"outlined"}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              type={"password"}
            />
          </Box>
          <Box mt={2} mb={3}>
            <Button type={"submit"} variant={"contained"} onSubmit={handleLogin}>
              {t("login_button")}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
