import {Box, Modal} from "@mui/material";

const style = {
  position: "absolute",
  overflow: "auto",
  top: "50%",
  left: "50%",
  height: "90%",
  width: "auto",
  transform: "translate(-50%, -50%)",
  borderColor: "white",
  outline: "none",
  backgroundColor: "white",
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ModalTemplate(props: { children: any; open: boolean; style?: any }) {
  return (
    <>
      <Modal open={props.open}>
        <Box sx={{ ...style, ...props.style }}>{props.children}</Box>
      </Modal>
    </>
  );
}
