import React from "react";
import "./App.css";
import Router from "./router/router";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  return (
    <>
      <CssBaseline />
      <Router />
    </>
  );
}

export default App;
