import {ReactNode, useEffect, useState} from "react";
import {apolloClient} from "../apollo";
import {UserAuthorizationDocument} from "../generated/graphql";
import Login from "../components/Login";
import {useSearchParams} from "react-router-dom";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [searchParams] = useSearchParams();
  const authQuery = searchParams.get("auth");

  if (authQuery === undefined) {
    setIsAuthenticated(false);
  }

  useEffect(() => {
    console.log("effect executed");
    apolloClient
      .query({
        query: UserAuthorizationDocument,
        fetchPolicy: "no-cache",
      })
      .then((data) => {
        setIsAuthenticated(true);
      })
      .catch((e) => {
        setIsAuthenticated(false);
      });
  }, [authQuery]);

  if (!isAuthenticated) {
    return <Login />;
  }

  return <>{children}</>;
}
