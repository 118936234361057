import AppBarLayout from "../layouts/AppBarLayout";
import {lazy, Suspense} from "react";
import {Navigate, useRoutes} from "react-router-dom";
import Questionnaire from "../pages/Questionnaire";
import Home from "../pages/Home";
import AppHandover from "../pages/AppHandover";
import Login from "../components/Login";
import AuthGuard from "../guards/AuthGuard";
import Results from "../pages/Results";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

const NotFound = Loadable(lazy(() => import("../pages/NotFound")));

export default function Router() {
  return useRoutes([
    {
      path: "/login",
      element: <AppBarLayout />,
      children: [{ path: "/login", element: <Login /> }],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <AppBarLayout />
          <ToastContainer/>
        </AuthGuard>
      ),
      children: [
        { element: <Home />, index: true },
        { path: "/questionnaire", element: <Questionnaire /> },
        { path: "/app-handover", element: <AppHandover /> },
        { path: "/results/:id", element: <Results/>}
      ],
    },
    {
      path: "*",
      element: (
        <AuthGuard>
          <AppBarLayout />
        </AuthGuard>
      ),
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },

  ]);
}
