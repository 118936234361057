import {Box, Button, CircularProgress, Container, Typography,} from "@mui/material";
import Question from "../components/question/Question";
import {QuestionnaireConfig} from "../data/QuestionConfig";
import {useStoreDispatch, useStoreSelector} from "../store/hooks";
import {useTranslation} from "react-i18next";
import {
    filteredQuestions,
    filterRelevantQuestions,
    nextQuestion,
    previousQuestion,
    QuestionnaireStates,
    selectAllAnswers,
    selectCurrentQuestionId,
    selectCurrentQuestionIndex,
    selectQuestionnaireState,
    submitAnswers,
} from "../store/questionnaireSlice";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import WarningDialog from "../components/question/WarningDialog";

function GoToAppHandoverButton({state}: { state: QuestionnaireStates }) {
    let isSubmitting = state === QuestionnaireStates.Submitting;
    return (
        <Box display={"flex"} alignItems={"center"} gap={3}>
            Zur Vermessung
            {isSubmitting ? <CircularProgress color={"inherit"} size={"1em"}/> : null}
        </Box>
    );
}

export interface ErrorMessage {
    show: boolean,
    title: string,
    content: string,
}

export default function Questionnaire() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>({show: false, content: "", title: ""});

    const dispatch = useStoreDispatch();

    const currentQuestion = useStoreSelector(selectCurrentQuestionId);
    const currentQuestionIndex = useStoreSelector(selectCurrentQuestionIndex);
    const questionnaireState = useStoreSelector(selectQuestionnaireState);
    const allAnswers = useStoreSelector(selectAllAnswers);
    let questionKeysForSelectedGroups = useStoreSelector(filteredQuestions)

    const isFirstQuestion = currentQuestionIndex === 0;
    const isLastQuestion = currentQuestion !== 'article_groups' && currentQuestionIndex === questionKeysForSelectedGroups.length - 1;

    const currentAnswers = Object.values(allAnswers)[currentQuestionIndex];
    const isOptional = QuestionnaireConfig[currentQuestion].optional;

    const {t} = useTranslation()

    function onBackClicked() {
        if (isFirstQuestion) {
            //TODO confirm abort
            navigate({pathname: "/", search: searchParams.toString()});
        } else {
            dispatch(previousQuestion());
        }
    }

    async function onNextClicked() {
        if (isOptional || (currentAnswers !== undefined && currentAnswers.length !== 0)) {
            if (isLastQuestion) {
                try {
                    await dispatch(submitAnswers()).unwrap();
                    navigate({pathname: "/app-handover", search: searchParams.toString()});
                } catch (e) {
                    setErrorMessage({
                        title: t("answers_sending_error_title"),
                        content: t("answers_sending_error_content"),
                        show: true
                    });
                    console.error(e);
                }
            } else {
                dispatch(filterRelevantQuestions());
                dispatch(nextQuestion());
            }
        } else {
            setErrorMessage({show: true, title: t("non_optional_warning_title"), content: ""});
        }
    }

    return (
        <>
            <Container className="" sx={{flexGrow: 1, textAlign: "center"}}>
                <Question
                    questionConfig={QuestionnaireConfig[currentQuestion]}></Question>
            </Container>
            <Box display="flex" justifyContent="space-between" p={5}>
                <Button variant="outlined" onClick={onBackClicked}>
                    <Typography fontSize={24} px={2}>
                        {/*TODO translate*/}
                        {isFirstQuestion ? "Abbrechen" : "Zurück"}
                    </Typography>
                </Button>

                <Button
                    variant="contained"
                    onClick={onNextClicked}
                    disabled={questionnaireState === QuestionnaireStates.Submitting}
                >
                    <Typography fontSize={24} px={2}>
                        {isLastQuestion ? <GoToAppHandoverButton state={questionnaireState}/> : "Weiter"}
                    </Typography>
                </Button>
            </Box>
            <WarningDialog errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>

        </>
    );
}
